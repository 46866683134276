import {Employer} from "../../graphql/generated/graphql";
import {Grid} from "@mui/material";
import React from "react";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import LogoDisplayEdit from "./LogoDisplayEdit";
import EmployerDisplay from "./EmployerDisplay";

type PropsType = {
    employer?: Employer;
    edit: () => void;
    noEmployerDisplay: ReactJSXElement;
    employerUpdated: (employerId: number) => void;
    isEditable: boolean;
}

const EmployerDisplayWithLogo = (props: PropsType) => {
    const {employer, noEmployerDisplay, edit, employerUpdated, isEditable} = props;
    if (!employer || employer.id < 1) {
        return noEmployerDisplay;
    }
    return <Grid display="flex" alignItems="flex-start">
        <Grid item xs={3.5}>
            <LogoDisplayEdit
                employerUpdated={employerUpdated}
                employer={employer}
            />
        </Grid>
        <Grid item xs={8.5}>
            <EmployerDisplay
                employer={employer}
                noEmployerDisplay={noEmployerDisplay}
                edit={edit}
                isEditable={isEditable}
            />
        </Grid>
    </Grid>;
}

export default EmployerDisplayWithLogo;