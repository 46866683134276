import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    NextLink,
    NormalizedCacheObject,
    Observable,
    Operation
} from '@apollo/client';
import {APOLLO_CLIENT, tokenExpired} from "./OneTwoPayApolloProvider";
import {AuthenticationFailureKey} from "./generated/graphql";
import {GraphQLError} from "graphql/error";


type OneTwoPayApolloClientType = {
    client: ApolloClient<any>
}

export function deleteFromApolloCache(typeName: string, objectId: number) {

    if (!!APOLLO_CLIENT) {
        APOLLO_CLIENT.cache.evict({id: APOLLO_CLIENT.cache.identify({__typename: typeName, id: objectId})});
        // Garbage collect references
        APOLLO_CLIENT.cache.gc();
        return;
    }
    throw new Error("Apollo client undefined. Please contact support.");
}


function handleAuthenticationFailures(error: GraphQLError,
                                      userNotFoundAction: () => void,
                                      anonUserNotFoundAction: () => void,
) {
    if (error.message.includes(AuthenticationFailureKey.UserNotFound)) {
        userNotFoundAction();
    }
    if (error.message.includes(AuthenticationFailureKey.AnonUserIdMissing)) {
        anonUserNotFoundAction();
    }
}

function getUserErrorTrigger(
    userNotFoundAction: () => void,
    anonUserNotFoundAction: () => void,
) {
    return new ApolloLink((operation, forward) => {
        return forward(operation).map(response => {
            if (response.errors) {
                response.errors.forEach(error => {
                    handleAuthenticationFailures(error, userNotFoundAction, anonUserNotFoundAction);
                });
            }
            return response;
        });
    });
}

export function getOneTwoPayApolloClient(accessToken: string | undefined,
                                         getNewToken: () => Promise<string | undefined>,
                                         userNotFoundAction: () => void,
                                         anonUserNotFoundAction: () => void,
): OneTwoPayApolloClientType {

    const userErrorTrigger = getUserErrorTrigger(userNotFoundAction, anonUserNotFoundAction);
    const httpLink = new HttpLink({uri: process.env.REACT_APP_GRAPHQL_URL});
    const authLink = new ApolloLink((operation: Operation, forward: NextLink) => new Observable(observer => {
            (async () => {
                try {

                    let token = accessToken;
                    if (!!accessToken && tokenExpired(accessToken)) {
                        token = await getNewToken();
                    }

                    if (!!token) {
                        operation.setContext({
                            headers: {
                                authorization: accessToken ? `Bearer ${token}` : ''
                            }
                        });
                    }
                    const handle = forward(operation).subscribe({
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer)
                    });
                    return () => {
                        if (handle) handle.unsubscribe();
                    };
                } catch (e) {
                    observer.error(e);
                }
            })();
        })
    );


    const onetwopayGraphClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
        link: userErrorTrigger.concat(authLink.concat(httpLink)),
        cache: new InMemoryCache()
    });

    return {
        client: onetwopayGraphClient
    }
}



