import React, {useState} from "react";
import EmployeesSelect from "../components/Report/EmployeesSelect";
import {EmployeeWithStatus} from "../graphql/generated/graphql";
import {Alert, Box, Container, Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import RoeInput from "../components/Report/Roe/RoeInput";
import CloseDialogButton from "../common/CloseDialogButton";

type PropsType = {
    closeReport: () => void;
}

const RoeReport = (props: PropsType) => {
    const [employeesSelected, setEmployeesSelected] = useState<Array<EmployeeWithStatus>>();
    const employee = !!employeesSelected && employeesSelected.length > 0 ? employeesSelected[0] : undefined;
    const [reloadEmployees, setReloadEmployees] = useState<boolean>(true);
    const [showRoeForm, setShowRoeForm] = useState(true);

    const {closeReport} = props;

    return <Dialog
        open={true}
        maxWidth="lg"
        fullWidth={true}
        sx={{'& .MuiDialog-paper': {minWidth: 'lg'}}}
    >
        <DialogTitle sx={{m: 0, p: 2, fontSize: '1.5rem'}}>
            <CloseDialogButton
                close={() => closeReport()}
            />
        </DialogTitle>
        <DialogContent>
            <Container maxWidth={"lg"}>
                <Box sx={{height: "auto", width: '100%', marginTop: "10px"}}>
                    <Typography variant={"h4"} sx={{pb: 1.5}}>
                        Please select an employee to continue.
                    </Typography>
                    <Typography>
                        Note that only employees with finalized pay stubs are shown here.
                    </Typography>

                    <EmployeesSelect
                        employeesSelectedUpdated={setEmployeesSelected}
                        selectedEmployees={employeesSelected}
                        multipleSelectionsAllowed={false}
                        reloadEmployees={reloadEmployees}
                        reloaded={() => setReloadEmployees(false)}
                        showNeighbours={showEm => setShowRoeForm(showEm)}
                    />

                    {!!employee && showRoeForm && (
                        <Dialog
                            open={!!employee}
                            fullWidth={true}
                            maxWidth="md"
                            sx={{
                                '& .MuiDialog-paper': {
                                    maxHeight: '90vh'
                                },
                            }}
                        >
                            <CloseDialogButton
                                close={() => setEmployeesSelected(undefined)}
                            />
                            <DialogTitle
                                sx={{
                                    mt: 3,
                                }}
                            >
                                <Alert
                                    severity={"success"}
                                    sx={{
                                        fontSize: '1.4rem',
                                    }}
                                >
                                    Need help? No Service Canada account? We will help you generate your ROE and submit
                                    it to Service Canada on your behalf. We will also provide a PDF copy.
                                    Two-hour turnaround during business hours. Additional fee applies.
                                    Just open a chat or give us a call at 1 800 837 8793. We're here to help.
                                </Alert>

                            </DialogTitle>
                            <RoeInput
                                employeeWithStatus={employee}
                                close={() => setEmployeesSelected(undefined)}
                                reloadEmployee={() => setReloadEmployees(true)}
                            />
                        </Dialog>
                    )}
                </Box>
            </Container>
        </DialogContent>
    </Dialog>
}

export default RoeReport;