import React from 'react';
import dayjs from 'dayjs';
import {useFieldArray, useForm} from 'react-hook-form';
import {Button, Dialog, DialogContent, DialogTitle, Grid, Typography} from '@mui/material';
import ReactHookFormDatePicker from '../../../common/ReactHookFormDatePicker';
import {StatHolidayPayInput} from '../../../graphql/generated/graphql';
import {DATE_FORMAT, getStringFromDate} from '../../../Utils/dateFormatter';
import OneTwoPayNumericTextField from "../../../common/OneTwoPayNumericTextField";
import CloseDialogButton from "../../../common/CloseDialogButton";

type PropsType = {
    statPay: Array<StatHolidayPayInput> | undefined | null;
    updateStatPay: (statPay: Array<StatHolidayPayInput>) => void;
    close: () => void;
};

type StatHoliday = {
    amount: string;
    holidayDate?: string | undefined | null;
    statHolidayNumber: number;
};

type StatHolidays = {
    statPay: Array<StatHoliday>;
};

function getAtLeastOneStatPay(statPay: Array<StatHolidayPayInput> | undefined | null): Array<StatHoliday> {
    if (!statPay || statPay.length < 1) {
        return [{
            amount: '',
            holidayDate: getStringFromDate(dayjs(), DATE_FORMAT),
            statHolidayNumber: 1,
        }];
    }
    return statPay;
}

const RoeStatPay: React.FC<PropsType> = ({statPay, updateStatPay, close}) => {
    const {
        control,
        register,
        getValues,
        formState: {errors}
    } = useForm<StatHolidays>({
        defaultValues: {
            statPay: getAtLeastOneStatPay(statPay),
        },
    });

    const {fields, append} = useFieldArray({
        control,
        name: 'statPay',
    });

    const saveStatHolidayPay = () => {
        const data = getValues();
        updateStatPay(data.statPay.map((statPay) => ({
            ...statPay,
        })));
        close();
    };

    const addAnotherStatHoliday = () => {
        append({
            amount: '',
            holidayDate: getStringFromDate(dayjs(), DATE_FORMAT),
            statHolidayNumber: fields.length + 1
        });
    };

    return (
        <Dialog open={true} fullWidth={true}>
            <DialogTitle>
                <CloseDialogButton
                    close={close}
                />
            </DialogTitle>
            <DialogContent>
                <Typography variant={"h6"}>Statutory holiday pay information (optional) </Typography>
                <form onSubmit={e => {
                    saveStatHolidayPay();
                    e.preventDefault();
                    e.stopPropagation();
                }} noValidate>
                    <Grid container spacing={1} sx={{mt: 1}}>
                        {fields.map((field, index) => (
                            <React.Fragment key={field.id}>
                                <Grid item xs={6}>
                                    <OneTwoPayNumericTextField
                                        label={"Amount"}
                                        amount={getValues(`statPay.${index}.amount`) || ""}
                                        {...register(`statPay.${index}.amount`)}
                                        error={!!errors.statPay?.[index]?.amount}
                                        errorText={errors.statPay?.[index]?.amount?.message}
                                        variant={"outlined"}

                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ReactHookFormDatePicker
                                        fieldName={`statPay.${index}.holidayDate`}
                                        label="Holiday date"
                                        control={control}
                                        height={51}
                                    />
                                </Grid>
                            </React.Fragment>
                        ))}
                        <Grid item xs={12}>
                            <Button onClick={addAnotherStatHoliday}>Add another stat holiday</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="error" onClick={close} style={{fontSize: '1.2rem'}}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" type="submit" style={{fontSize: '1.2rem'}}>
                                Save statutory holiday pay
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default RoeStatPay;
