import React, {ForwardRefExoticComponent, ReactNode, RefAttributes} from "react";
import EditIcon from "@mui/icons-material/Edit";
import {Dialog, DialogActions, DialogContent, DialogTitle, SxProps, Theme} from "@mui/material";
import OneTwoPayTooltip from "./OneTwoPayTooltip";
import CloseDialogButton from "./CloseDialogButton";

type PropsType = {
    dialogTitle: ReactNode;
    children: ReactNode;
    toolTipText: string;
    openDialog: boolean;
    setOpenDialog: (openDialog: boolean) => void;
    triggerCancelEditAction?: () => void;
    editIcon?: ForwardRefExoticComponent<RefAttributes<any>>;
    editIconSx?: SxProps<Theme> | undefined;
}

const EditDialog = (props: PropsType) => {
    const {
        toolTipText,
        dialogTitle,
        children,
        openDialog,
        setOpenDialog,
        editIcon,
        triggerCancelEditAction,
        editIconSx
    } = props;


    const defaultEditIcon = React.forwardRef<SVGSVGElement>((props, ref) => (
        <EditIcon
            {...props}
            ref={ref}
            onClick={() => {
                setTimeout(() => setOpenDialog(true), 0);
            }}
            sx={editIconSx}
            color={"primary"}
            data-testid={"edit-income-item-icon"}
        />));

    if (!openDialog) {
        return <OneTwoPayTooltip
            tipContent={toolTipText}
            TooltipTarget={editIcon || defaultEditIcon}
        >
        </OneTwoPayTooltip>
    }

    return <>

    <Dialog
            fullWidth
            maxWidth={"md"}
            open={openDialog}
        >
            <DialogTitle sx={{ml: 2}}>
                {dialogTitle}
                <CloseDialogButton
                    close={() => {
                        !!triggerCancelEditAction && triggerCancelEditAction();
                        setOpenDialog(false);
                    }}
                />
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    </>
}
export default EditDialog;