import {Button, Typography} from "@mui/material";
import React, {useContext, useState} from "react";
import UserSearch from "./UserSearch";
import {assumedUserContext} from "./AssumedUserContext";
import {userContext} from "./UserContext";
import {User} from "@auth0/auth0-react";


function getUserDescription(assumedUser: User, signedInUser: User) {
    if (signedInUser.isAdmin) {
        return `${assumedUser.firstName} ${assumedUser.lastName} (${assumedUser.id}) ${assumedUser.email}`;
    }
    return `${assumedUser.firstName} ${assumedUser.lastName} (${assumedUser.email})`;
}

const AssumedUserDisplaySearch = () => {

    const {user: signedInUser} = useContext(userContext);
    const {assumedUser, setAssumedUser} = useContext(assumedUserContext);
    const [changeUser, setChangeUser] = useState(false);

    let userDescription = getUserDescription(assumedUser, signedInUser);

    if (!signedInUser || !signedInUser.id) {
        return <></>;
    }

    return <>
        <Typography
            color={"text.primary"}
        >
            {userDescription}
        </Typography>

        {
            signedInUser.isAdmin &&
            <>
                <Button
                    onClick={() => setChangeUser(true)}
                >
                    Change user
                </Button>
                <UserSearch
                    user={assumedUser}
                    setUser={setAssumedUser}
                    signedInUser={signedInUser}
                    open={changeUser}
                    close={() => setChangeUser(false)}
                />
            </>
        }
    </>
}
export default AssumedUserDisplaySearch;