import {Grid, Typography} from "@mui/material";
import React, {useContext} from "react";
import OneTwoPayDateRangePicker from "../../common/OneTwoPayDateRangePicker";
import {Dayjs} from "dayjs";
import {DateRange} from "@mui/x-date-pickers-pro";
import {useSavePayPeriodMutation} from "../../graphql/generated/graphql";
import {getFormattedDateStringFromNullableDateOrString, isDateToStringResultInvalid} from "../../Utils/dateFormatter";
import useAnonCookie from "../../security/useAnonCookie";
import usePayStubIdManager from "./usePayStubIdManager";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import {assumedUserContext} from "../User/AssumedUserContext";


type PropsType = {
    isEditable: boolean;
    payPeriod: DateRange<Dayjs>;
    updatePayPeriod: (start: string, end: string, payStubId: number) => void;
}

const PayPeriodDisplayEdit = (props: PropsType) => {

    const {isEditable, payPeriod, updatePayPeriod} = props;

    const {getAnonUserId} = useAnonCookie();
    const {getPayStubId} = usePayStubIdManager();
    const {assumedUser} = useContext(assumedUserContext);

    const [
        savePayPeriod,
        {
            loading,
            error
        }
    ] = useSavePayPeriodMutation();

    const setPayPeriod = (dateRange: DateRange<Dayjs>) => {
        const startDate = getFormattedDateStringFromNullableDateOrString(dateRange[0]);
        const endDate = getFormattedDateStringFromNullableDateOrString(dateRange[1]);
        if (!startDate || !endDate || isDateToStringResultInvalid(startDate) || isDateToStringResultInvalid(endDate)) {
            return;
        }
        savePayPeriod({
            variables: {
                input: {
                    periodStart: startDate,
                    periodEnd: endDate,
                    payStubId: getPayStubId(),
                },
                anonUserId: getAnonUserId(),
                userId: assumedUser.id
            }
        })
            .then(savePayPeriodResult => {
                let result = savePayPeriodResult.data?.savePayPeriod;
                if (!!result) {
                    updatePayPeriod(result.payPeriodStart, result.payPeriodEnd, result.payStubId);
                }
            });
    }

    return <>
        <Grid container alignItems="center"  spacing={{xs: 1, sm: 2}}>
            <Grid container flexDirection="row" alignItems="center" spacing={{xs: 3, sm: 2}}>
                <Grid item>
                    <Typography variant="h6" sx={{fontWeight: "500", mt: 2, ml: {xs:2}}}>
                        Pay Period:
                    </Typography>
                </Grid>
                <Grid item xs={12} display="flex" sx={{ml: {xs: 1, sm:0.4}}} >
                    <OneTwoPayDateRangePicker
                        dateRange={payPeriod}
                        setDateRange={setPayPeriod}
                        disabled={!isEditable}
                        label={"Start-End"}
                        // label={"Pay period"}
                    />
                </Grid>
                {(loading || !!error) && (
                    <Grid item >
                        <LoadingErrorDisplay
                            loading={loading}
                            apolloError={error}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    </>
}

export default PayPeriodDisplayEdit;