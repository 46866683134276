import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField
} from "@mui/material";
import React, {useCallback, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {User, UserProfileInput, useSaveUserProfileMutation} from "../graphql/generated/graphql";
import LoadingErrorDisplay from "./LoadingErrorDisplay";
import {Link} from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import useAnonCookie from "../security/useAnonCookie";
import ReactHookFormSelect from "./ReactHookFormSelect";
import useGetProvinces from "../Utils/useGetProvinces";
import {useAuth0} from "@auth0/auth0-react";
import CloseDialogButton from "./CloseDialogButton";


type PropsType = {
    userProfile: User | undefined;
    profileCreated: (userProfile: User) => void;
}


function getUserProfileInput(userProfile: User | undefined): UserProfileInput {
    return {
        id: userProfile?.id,
        firstName: userProfile?.firstName || "",
        lastName: userProfile?.lastName || "",
        mobile: userProfile?.mobile || "",
        email: userProfile?.email || "",
        provinceCode: userProfile?.provinceCode || "",
    }
}

const UserProfileDialog = (props: PropsType) => {
    const {userProfile, profileCreated} = props;

    const [openDialog, setOpenDialog] = useState(true);
    const [error, setError] = useState<string>();
    const [cancelVerify, setCancelVerify] = useState(false);
    const {getAnonUserId} = useAnonCookie();

    const {logout} = useAuth0();
    const {
        register,
        handleSubmit,
        formState: {errors},
        control,
    } = useForm<UserProfileInput>({
            defaultValues: getUserProfileInput(userProfile),
            mode: "onChange"
        }
    );

    const {
        provinces,
        error: provincesError,
        loading: provincesLoading
    } = useGetProvinces();

    const [
        saveUserProfile,
        {
            loading: saveUserProfileLoading,
            error: saveUserProfileError
        }
    ] = useSaveUserProfileMutation();

    const saveUserProfileToServer = useCallback((data: UserProfileInput) => {
        saveUserProfile({
            variables: {
                userProfileInput: data,
                anonUserId: getAnonUserId()
            }
        })
            .then(result => {
                const successful = result.data?.saveUserProfile.successful;
                let errorMessage = result.data?.saveUserProfile.message;
                if (successful && !!result.data?.saveUserProfile.user) {
                    setOpenDialog(false);
                    profileCreated(result.data?.saveUserProfile.user);
                } else if (!!errorMessage) {
                    setError(errorMessage);
                }
            })
            .catch(error => {
                console.error(error);
                setError(error)
            });
    }, [saveUserProfile, getAnonUserId, profileCreated]);

    const cancelAndSignout = useCallback(() => {
        logout({
            logoutParams: {
                returnTo: process.env.REACT_APP_MARKETING_URL,
            },
        });
    }, [logout]);

    function cancelUserProfileCreate() {
        setOpenDialog(false);
        setCancelVerify(true);
    }

    function continueUserProfileCreate() {
        setOpenDialog(true);
        setCancelVerify(false);
    }

    if (cancelVerify) {
        return <Dialog
            open={cancelVerify}
        >
            <DialogTitle>
                Cancel create account?
                <CloseDialogButton
                    close={continueUserProfileCreate}
                />
            </DialogTitle>
            <DialogContent>
                Are you sure you want to cancel? If you don't want to continue, you'll be signed out.
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={cancelAndSignout}
                    variant='contained'
                    color='error'
                    sx={{mr: 2}}
                >
                    Cancel and sign out
                </Button>
                <Button
                    type={"button"}
                    variant='contained'
                    onClick={continueUserProfileCreate}
                >
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    }

    return <Dialog
        open={openDialog}
    >
        <form noValidate onSubmit={handleSubmit(saveUserProfileToServer)} id="user-create">
            <DialogTitle>
                Please provide a few details to finish setting up your account
                <CloseDialogButton
                    close={cancelUserProfileCreate}
                />
            </DialogTitle>
            <DialogContent>
                <Grid container alignItems="center" spacing={1.5} sx={{mt: 2}}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={"Email"}
                            disabled={true}
                            {...register("email")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={"First Name"}
                            {...register("firstName", {required: "First name is required"})}
                            error={!!errors.firstName}
                            helperText={errors.firstName?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={"Last Name"}
                            {...register("lastName", {required: "Last name is required"})}
                            error={!!errors.lastName}
                            helperText={errors.lastName?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={"Mobile phone"}
                            {...register("mobile", {required: "Mobile is required"})}
                            error={!!errors.mobile}
                            helperText={errors.mobile?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {
                            (provincesLoading || provincesError) &&
                            <LoadingErrorDisplay
                                loading={provincesLoading}
                                apolloError={provincesError}
                            />
                        }
                        {!!provinces &&
                            <ReactHookFormSelect<UserProfileInput>
                                nameOfFormField={"provinceCode"}
                                label={"Default province"}
                                control={control}
                                requiredMessage={"Default province is required"}
                                error={errors.provinceCode}
                            >
                                {provinces.map((province) => (
                                    <MenuItem key={province.key} value={province.key}>
                                        {province.label}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                        }
                    </Grid>
                    <Grid item xs={6} sx={{mt: 2, ml: 2}}>
                        <Link
                            // style={{ color: "primary", marginLeft: "20px", marginTop: "40px" }}
                            color={'primary'}
                            to={`${process.env.REACT_APP_MARKETING_URL}/terms-of-service`}
                        >
                            Terms & conditions
                        </Link>
                    </Grid>
                    <Grid item xs={5} sx={{mt: 2, ml: 2}}>
                        <Link
                            style={{color: "primary", marginLeft: "20px", marginTop: "40px"}}
                            // color={'primary'}
                            to={`${process.env.REACT_APP_MARKETING_URL}/privacy-policy`}
                        >
                            Privacy policy
                        </Link>
                    </Grid>
                    <Grid item xs={12} sx={{mt: 2, ml: 5}}>
                        <Controller
                            control={control}
                            name="termsAccepted"
                            rules={{required: 'Please accept the terms & conditions and privacy policy to continue.'}}
                            render={({field}) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={field.value || false}
                                            onChange={e => field.onChange(e.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label={"I accept the terms & conditions and privacy policy"}
                                />
                            )}
                        />
                        {errors.termsAccepted && (
                            <FormHelperText error> {errors.termsAccepted.message} </FormHelperText>)}
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions sx={{justifyContent: 'flex-start'}}>
                <LoadingErrorDisplay
                    loading={saveUserProfileLoading}
                    apolloError={saveUserProfileError}
                    stringError={error}
                >
                    <Button
                        onClick={cancelUserProfileCreate}
                        variant='contained'
                        color='error'
                        sx={{mr: 2}}
                    >
                        Cancel
                    </Button>
                    <Button
                        type={"submit"}
                        variant='contained'
                    >
                        Save
                    </Button>
                </LoadingErrorDisplay>
            </DialogActions>
        </form>
    </Dialog>
}
export default UserProfileDialog;