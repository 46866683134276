import {gql} from "@apollo/client";

const GET_EMPLOYERS = gql`
    query getEmployers($userId: Int, $anonUserId: String!) {
        getEmployers(userId: $userId, anonUserId: $anonUserId) {
            id
            name
            detailedName
            address1
            address2
            city
            province
            postalCode
            logoUrl
            logoWidth
            logoHeight
        }
    }
`;

export default GET_EMPLOYERS;