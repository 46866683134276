import {IncomeType, TaxTreatment} from "../../graphql/generated/graphql";
import {Divider, Grid, TextField, Typography} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, {useState} from "react";
import IncomeTypeEdit from "./IncomeTypeEdit";
import EditDialog from "../../common/EditDialog";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import CloseDialogButton from "../../common/CloseDialogButton";

type PropsType = {
    incomeTypes: Array<IncomeType>
    close: () => void;
    incomeType: IncomeType;
    currentPayStubId: number | undefined;
    setIncomeType: (incomeType: IncomeType) => void;
}

function getEmptyIncomeType(taxTreatment: TaxTreatment): IncomeType {
    return {
        id: "",
        label: "",
        rateLabel: "",
        unitsLabel: "",
        taxTreatment: taxTreatment,
        displayOrder: 0,
        isBenefit: false
    };
}

type IncomeTypeAutoComplete = {
    label: string;
    incomeType: IncomeType
}
const getIncomeTypeAutoComplete = (incomeType: IncomeType): IncomeTypeAutoComplete => {
    return {
        label: incomeType.label,
        incomeType: incomeType
    }
}

const SALARY_TAX_TREATMENT: TaxTreatment = {
    code: "SALARY",
    label: "Regular pay",
    description: ""
}

const getIncomeTypeOptions = (incomeTypes: Array<IncomeType>): ReadonlyArray<IncomeTypeAutoComplete> => {
    let incomeTypeOptions = incomeTypes.map(incomeType => {
            return getIncomeTypeAutoComplete(incomeType);
        }
    );
    const newIncomeTypeOption: IncomeTypeAutoComplete = {
        label: "Add a new type + ",
        incomeType: getEmptyIncomeType(SALARY_TAX_TREATMENT)
    };
    incomeTypeOptions.push(newIncomeTypeOption);
    return incomeTypeOptions;
}


const IncomeTypeSelectEdit = (props: PropsType) => {
    const {
        incomeTypes,
        close,
        incomeType,
        setIncomeType,
        currentPayStubId,
    } = props;


    const [editMode, setEditMode] = useState<boolean>(false);


    const selectIncomeType = (selectedAutoCompleteOption: IncomeTypeAutoComplete) => {
        const selectedType = selectedAutoCompleteOption.incomeType;
        if (!selectedType.id || selectedType.id === "0") {
            // newIncomeType();
            setEditMode(true);
        } else {
            setIncomeType(selectedType);
        }
    }

    let autoCompleteOptions: ReadonlyArray<IncomeTypeAutoComplete> = getIncomeTypeOptions(incomeTypes);

    if (!incomeType) {
        return <LoadingErrorDisplay
            loading={true}
        />
    }

    return <><Grid container direction={"row"} alignItems="center" sx={{mt: 2}}>
        <Grid item xs={9}>
            <Autocomplete
                disabled={editMode}
                options={autoCompleteOptions}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select income type"
                        fullWidth
                        InputLabelProps={{
                            style: {fontSize: '1.6rem'},
                        }}
                        inputProps={{
                            ...params.inputProps,
                            style: {fontSize: '1.4rem'},
                        }}
                        sx={{
                            mt: 3,
                            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                                backgroundColor: 'white',
                                paddingRight: '5px',
                            },
                        }}
                    />
                )}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <li {...props} style={{fontSize: '1.4rem'}}>
                        {option.label}
                    </li>
                )}
                value={getIncomeTypeAutoComplete(incomeType)}
                isOptionEqualToValue={(option, value) => option.incomeType.id === value.incomeType.id}
                onChange={(event: any, newValue: any) => {
                    console.log("event: ", JSON.stringify(event.target.id, null, 2));
                    if (!!newValue) {
                        selectIncomeType(newValue);
                    }
                }}
            />
        </Grid>
        <Grid>
            <Grid container direction={"row"}>
                <Grid item xs={2} sx={{mt: 2, ml: 2}}>
                    <EditDialog
                        dialogTitle={"Edit income type"}
                        toolTipText={"Edit income type"}
                        openDialog={editMode}
                        setOpenDialog={setEditMode}
                    >
                        <IncomeTypeEdit
                            currentPayStubId={currentPayStubId}
                            incomeType={incomeType}
                            setIncomeType={setIncomeType}
                            close={() => setEditMode(false)}
                            incomeTypes={incomeTypes}
                        />
                    </EditDialog>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sx={{mt: 1, ml: 1}}>
            <Typography
                variant="body2"
            >
                {incomeType.taxTreatment.description}
            </Typography>
        </Grid>
    </Grid>

        <Divider sx={{mt: 2, mb: 0}}/>
        <CloseDialogButton
            close={close}
        />
    </>
}
export default IncomeTypeSelectEdit;