import {useFieldArray, useForm} from "react-hook-form";
import {OtherMoniesCode, OtherMoniesInput} from "../../../graphql/generated/graphql";
import {Button, Dialog, DialogContent, DialogTitle, Divider, Grid, MenuItem, Typography} from "@mui/material";
import React from "react";
import ReactHookFormDatePicker from "../../../common/ReactHookFormDatePicker";
import dayjs from "dayjs";
import {DATE_FORMAT, getStringFromDate} from "../../../Utils/dateFormatter";
import ReactHookFormSelect from "../../../common/ReactHookFormSelect";
import OneTwoPayNumericTextField from "../../../common/OneTwoPayNumericTextField";
import CloseDialogButton from "../../../common/CloseDialogButton";

type PropsType = {
    otherMonies: Array<OtherMoniesInput> | undefined | null
    updateOtherMonies: (otherMonies: Array<OtherMoniesInput>) => void;
    close: () => void;
}

type OtherMoniesArray = {
    otherMonies: Array<OtherMonies>
}
type OtherMonies = {
    amount: string;
    startDate?: string | undefined | null;
    endDate?: string | undefined | null;
    otherMoniesNumber: number;
    otherMoniesCode: OtherMoniesCode;
}

function getAtLeastOneOtherMonies(otherMonies: Array<OtherMoniesInput> | undefined | null): Array<OtherMonies> {
    if (!otherMonies || otherMonies.length < 1) {
        return [{
            amount: "0",
            startDate: getStringFromDate(dayjs(), DATE_FORMAT),
            endDate: getStringFromDate(dayjs(), DATE_FORMAT),
            otherMoniesNumber: 1,
            otherMoniesCode: OtherMoniesCode.O00Other
        }];
    }
    return otherMonies;
}

const RoeOtherMonies = (props: PropsType) => {
    const {otherMonies, updateOtherMonies, close} = props;
    const {
        control,
        register,
        getValues,
        watch,
        formState: {errors},
    } = useForm<OtherMoniesArray>({
        defaultValues: {
            otherMonies: getAtLeastOneOtherMonies(otherMonies)
        }
    });

    const {fields, append} = useFieldArray({
        control,
        name: "otherMonies",
    });

    function saveOtherMonies() {
        const data = getValues();
        updateOtherMonies(data.otherMonies);
        close();
    }

    function addAnotherOtherMonies() {
        append({
            amount: "",
            startDate: getStringFromDate(dayjs(), DATE_FORMAT),
            endDate: getStringFromDate(dayjs(), DATE_FORMAT),
            otherMoniesNumber: fields.length + 1,
            otherMoniesCode: OtherMoniesCode.O00Other
        });
    }

    const codes = Object.values(OtherMoniesCode);
    return <Dialog
        open={true}
        fullWidth={true}
    >
        <DialogTitle>
            <CloseDialogButton
                close={close}
            />
        </DialogTitle>
        <DialogContent>
            <form noValidate onSubmit={e => {
                saveOtherMonies();
                e.preventDefault();
                e.stopPropagation();
            }}>
                <Typography variant={"h6"}>Other monies information (optional)</Typography>

                <Grid container spacing={2} sx={{mt: 1}}>
                    {fields.map((field, index) => {
                        let amount = watch(`otherMonies.${index}.amount`) || "";
                        return (
                            <div key={field.id}>
                                <Grid container item xs={12} spacing={2} sx={{mt: 1}}>
                                    <Grid item xs={6}>
                                        <ReactHookFormSelect
                                            control={control}
                                            label={"Other monies code"}
                                            nameOfFormField={`otherMonies.${index}.otherMoniesCode`}
                                            requiredMessage={"Other monies code is required"}
                                        >
                                            {codes.map(code => (
                                                <MenuItem key={code} value={code}>
                                                    {code}
                                                </MenuItem>
                                            ))}
                                        </ReactHookFormSelect>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <OneTwoPayNumericTextField
                                            label={"Amount"}
                                            amount={amount}
                                            {...register(`otherMonies.${index}.amount`)}
                                            error={!!errors.otherMonies && !!errors.otherMonies[index]?.amount}
                                            errorText={errors.otherMonies && errors.otherMonies[index]?.amount?.message}
                                            variant={"outlined"}

                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ReactHookFormDatePicker
                                            fieldName={`otherMonies.${index}.startDate`}
                                            label={"Start date"}
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ReactHookFormDatePicker
                                            fieldName={`otherMonies.${index}.endDate`}
                                            label={"End date"}
                                            control={control}
                                        />
                                    </Grid>
                                </Grid>
                                <Divider sx={{borderColor: 'grey', borderWidth: '.5px', width: '100%', mt: 2}}/>
                            </div>
                        );
                    })}
                    <Grid item xs={12}>
                        <Button onClick={() => addAnotherOtherMonies()}>
                            Add more other monies
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant={"contained"}
                            style={{fontSize: '1.2rem'}}
                            color={"error"}
                            onClick={() => close()}
                            size={"large"}
                        >
                            {"Cancel"}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant={"contained"}
                            type={"submit"}
                            style={{fontSize: '1.2rem'}}
                            size={"large"}
                        >
                            Save other monies
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </DialogContent>
    </Dialog>
}
export default RoeOtherMonies;