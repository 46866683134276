import React, {forwardRef, useState} from "react";
import {Button, Card, Grid, IconButton, Typography} from "@mui/material";
import LogoDisplay from "../../common/LogoDisplay";
import {Employer} from "../../graphql/generated/graphql";
import EditDialog from "../../common/EditDialog";
import AddEditLogo from "./AddEditLogo";
import DeleteLogo from "./DeleteLogo";
import DeleteIcon from "@mui/icons-material/Delete";

type PropsType = {
    employerUpdated: (employerId: number) => void,
    employer: Employer;
}
const LogoDisplayEdit = (props: PropsType) => {
    const {employerUpdated, employer} = props;

    const [editLogo, setEditLogo] = useState<boolean>(false);
    const [deleteLogoNow, setDeleteLogoNow] = useState<boolean>(false);

    if (!employer.id) {
        return <Typography>A logo can be added after saving an employer</Typography>;
    }
    if (!editLogo && !employer.logoUrl) {
        return <>
            <Card sx={{height: "100%"}}>
                <Button
                    sx={{p: 1, mt: 2}}
                    fullWidth
                    onClick={() => setEditLogo(true)}
                >
                    Add Logo
                </Button>
            </Card>
        </>
    }

    const deleteIcon = forwardRef<HTMLButtonElement>((props, ref) => (
        <IconButton
            {...props}
            ref={ref}
            aria-label="delete"
            onClick={() => setDeleteLogoNow(true)}
            color={'error'}
        >
            <DeleteIcon sx={{fontSize: '1.8rem', padding: '0'}}/>
        </IconButton>

    ));

    return <>
        <Grid item xs={12}>
            <LogoDisplay
                logoUrl={employer.logoUrl}
                logoWidth={employer.logoWidth}
                logoHeight={employer.logoHeight}
            />
        </Grid>
        <Grid container direction={"row"}>
            <Grid item xs={3}>
                <EditDialog
                    dialogTitle={"Delete employer logo"}
                    openDialog={deleteLogoNow}
                    setOpenDialog={setDeleteLogoNow}
                    toolTipText={"Delete logo"}
                    editIcon={deleteIcon}
                >
                    <DeleteLogo
                        logoDeleted={employerId => {
                            setDeleteLogoNow(false);
                            employerUpdated(employerId);
                        }}
                        employer={employer}
                        deleteLogoNow={deleteLogoNow}
                        cancel={() => setDeleteLogoNow(false)}
                    />
                </EditDialog>
            </Grid>
            <Grid item xs={3}>
                <EditDialog
                    dialogTitle={"Add / edit employer logo"}
                    openDialog={editLogo}
                    setOpenDialog={setEditLogo}
                    toolTipText={"Add / edit logo"}
                    editIconSx={{mt: '8px', fontSize: '1.7rem'}}
                >
                    <AddEditLogo
                        logoUpdated={employerId => {
                            employerUpdated(employerId);
                            setEditLogo(false);
                        }}
                        editLogo={editLogo}
                        employer={employer}
                        setEditLogo={setEditLogo}
                    />
                </EditDialog>
            </Grid>

        </Grid>
    </>
}
export default LogoDisplayEdit;