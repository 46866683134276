import useAnonCookie from "../../security/useAnonCookie";
import {
    useGetNumberOfAnonymousPayStubsToTransferQuery,
    useTransferAnonUserDataMutation
} from "../../graphql/generated/graphql";
import React, {useCallback, useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import CloseDialogButton from "../../common/CloseDialogButton";
import useSystemNotices from "../../Utils/useSystemNotices";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";

type PropsType = {
    close: () => void;
}

const AnonymousDataTransferDialog = (props: PropsType) => {
    const {close} = props;

    const {getAnonUserId} = useAnonCookie();
    const {sendNotice} = useSystemNotices();
    const [openDialog, setOpenDialog] = useState(false);
    const [error, setError] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();

    const {
        data: payStubsToTransferData,
        error: payStubsToTransferError

    } = useGetNumberOfAnonymousPayStubsToTransferQuery({variables: {anonUserId: getAnonUserId()}});

    const [
        transferAnonUserData,
        {
            loading: transferAnonUserDataLoading,
            error: transferAnonUserDataError,

        }] = useTransferAnonUserDataMutation({variables: {anonUserId: getAnonUserId()}});


    const transferAnonData = useCallback(() => {
        transferAnonUserData()
            .then(result => {
                const successful = result.data?.transferAnonUserData.successful;
                if (successful) {
                    setSuccessMessage("Transfer complete.");
                } else {
                    let message = result.data?.transferAnonUserData.message;
                    setError(`Transfer failed: ${message}`);
                    sendNotice(`Error transferring anonymous user data: ${message}`);
                }
            })
            .catch(error => {
                setError(`Transfer failed: ${error.message}`);
                sendNotice(`Error transferring anonymous user data: ${error.message}`);
            });
    }, [transferAnonUserData, sendNotice]);

    useEffect(() => {
        if (!payStubsToTransferData) {
            return;
        }
        const numPayStubsToTransfer = payStubsToTransferData?.getNumberOfAnonymousPayStubsToTransfer.numPayStubsToTransfer;
        if (!!numPayStubsToTransfer && numPayStubsToTransfer > 0) {
            setOpenDialog(true);
        } else {
            setOpenDialog(false);
            close();
        }
    }, [close, payStubsToTransferData]);

    useEffect(() => {
        if (!!payStubsToTransferError) {
            sendNotice(`Error getting number of anonymous pay stubs to transfer: ${payStubsToTransferError.message}`);
            console.error(payStubsToTransferError);
        }
    }, [sendNotice, payStubsToTransferError]);

    return <Dialog
        open={openDialog}
    >
        <DialogTitle>
            User data transfer
            <CloseDialogButton
                close={close}
            />
        </DialogTitle>
        <DialogContent>
            {
                !!successMessage &&
                <Typography
                    variant={"body1"}
                >
                    {successMessage}
                </Typography>
            }
            {
                !successMessage &&
                <Typography
                    variant={"body1"}
                >
                    It appears that you
                    have {payStubsToTransferData?.getNumberOfAnonymousPayStubsToTransfer.numPayStubsToTransfer} pay
                    stubs created before signing up to OneTwoPay. Would
                    you like to transfer these pay stubs and the associated data into your account? Or would you like to
                    start fresh?
                </Typography>
            }
        </DialogContent>
        <DialogActions>
            {
                !!successMessage &&
                <Button
                    onClick={close} // in case of temptation, resist the urge to navigate to a specific page from here. Many potential routes will result from this and the navigation needs to stay flexible.
                    variant={"outlined"}
                    size={"large"}
                    sx={{
                        fontSize: "14px",
                        m: 1,
                        p: 2,
                        '&:hover': {
                            transform: 'translateY(-2px)'
                        }
                    }}
                >
                    Continue
                </Button>
            }
            {
                !successMessage && <>
                    <Button
                        onClick={close}
                        variant={"outlined"}
                        size={"large"}
                        sx={{
                            fontSize: "14px",
                            m: 1,
                            p: 2,
                            '&:hover': {
                                transform: 'translateY(-2px)'
                            }
                        }}
                    >
                        Start fresh
                    </Button>
                    <Button
                        onClick={transferAnonData}
                        variant={"outlined"}
                        size={"large"}
                        disabled={transferAnonUserDataLoading || !!transferAnonUserDataError}
                        sx={{
                            fontSize: "14px",
                            m: 1,
                            p: 2,
                            '&:hover': {
                                transform: 'translateY(-2px)'
                            }
                        }}
                    >
                        Transfer pay stubs
                    </Button>
                </>
            }
            <LoadingErrorDisplay
                loading={transferAnonUserDataLoading}
                apolloError={transferAnonUserDataError}
                stringError={error}
            />
        </DialogActions>
    </Dialog>;
}
export default AnonymousDataTransferDialog;