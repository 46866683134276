import {Divider, Grid, TextField, Typography} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, {useState} from "react";
import EditDialog from "../../common/EditDialog";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import CustomDeductionTypeEdit from "./DeductionTypeEdit";
import {DeductionType, TaxTreatment} from "../../graphql/generated/graphql";
import CloseDialogButton from "../../common/CloseDialogButton";

type PropsType = {
    deductionTypes: Array<DeductionType>
    close: () => void;
    deductionType: DeductionType;
    currentPayStubId: number | undefined;
    setCustomDeductionType: (deductionType: DeductionType) => void;
}

// this is not ideal. These are defined in the API and repeating that here is no good. Need a better way to do this.
const NO_TAX_TREATMENT: TaxTreatment = {
    code: "NO_TAX_TREATMENT",
    label: "No tax impact",
    description: "Deductions with this type will not affect how taxes are calculated."
}

function getEmptyCustomDeductionType(taxTreatment: TaxTreatment): DeductionType {
    return {
        id: 0,
        label: "",
        taxTreatment: taxTreatment,
        displayOrder: 0,
    };
}

type CustomDeductionTypeAutoComplete = {
    label: string;
    deductionType: DeductionType
}
const getCustomDeductionTypeAutoComplete = (deductionType: DeductionType): CustomDeductionTypeAutoComplete => {
    return {
        label: deductionType.label,
        deductionType: deductionType
    }
}

const getCustomDeductionTypeOptions = (deductionTypes: Array<DeductionType>): ReadonlyArray<CustomDeductionTypeAutoComplete> => {
    let deductionTypeOptions = deductionTypes
        .map(deductionType => {
                return getCustomDeductionTypeAutoComplete(deductionType);
            }
        );
    const newCustomDeductionTypeOption: CustomDeductionTypeAutoComplete = {
        label: "Add a new type + ",
        deductionType: getEmptyCustomDeductionType(NO_TAX_TREATMENT)
    };
    deductionTypeOptions.push(newCustomDeductionTypeOption);
    return deductionTypeOptions;
}


const CustomDeductionTypeSelectEdit = (props: PropsType) => {
    const {
        deductionTypes,
        close,
        deductionType,
        setCustomDeductionType,
        currentPayStubId,
    } = props;


    const [editMode, setEditMode] = useState<boolean>(false);


    const selectCustomDeductionType = (selectedAutoCompleteOption: CustomDeductionTypeAutoComplete) => {
        const selectedType = selectedAutoCompleteOption.deductionType;
        if (!selectedType.id || selectedType.id === 0) {
            // newCustomDeductionType();
            setEditMode(true);
        } else {
            setCustomDeductionType(selectedType);
        }
    }

    let autoCompleteOptions: ReadonlyArray<CustomDeductionTypeAutoComplete> = getCustomDeductionTypeOptions(deductionTypes);

    if (!deductionType) {
        return <LoadingErrorDisplay
            loading={true}
        />
    }

    return <><Grid container direction={"row"} alignItems="center" sx={{mt: 2}}>
        <Grid item xs={9}>
            <Autocomplete
                disabled={editMode}
                disablePortal
                options={autoCompleteOptions}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select deduction type"
                        fullWidth
                        InputLabelProps={{
                            style: {fontSize: '1.6rem'},
                        }}
                        inputProps={{
                            ...params.inputProps,
                            style: {fontSize: '1.4rem'},
                        }}
                        sx={{
                            mt: 3,
                            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                                backgroundColor: 'white',
                                paddingRight: '5px',
                            },
                        }}
                    />
                )}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <li {...props} style={{fontSize: '1.4rem'}}>
                        {option.label}
                    </li>
                )}
                value={getCustomDeductionTypeAutoComplete(deductionType)}
                isOptionEqualToValue={(option, value) => option.deductionType.id === value.deductionType.id}
                onChange={(event: any, newValue: any) => {
                    console.log("event: ", JSON.stringify(event.target.id, null, 2));
                    if (!!newValue) {
                        selectCustomDeductionType(newValue);
                    }
                }}
            />
        </Grid>
        <Grid>
            <Grid container direction={"row"}>
                <Grid item xs={2}
                      sx={{mt: 2, ml: 2}}
                >
                    <EditDialog
                        dialogTitle={"Edit deduction type"}
                        toolTipText={"Edit deduction type"}
                        openDialog={editMode}
                        setOpenDialog={setEditMode}
                    >
                        <CustomDeductionTypeEdit
                            currentPayStubId={currentPayStubId}
                            deductionType={deductionType}
                            setCustomDeductionType={setCustomDeductionType}
                            close={() => setEditMode(false)}
                            deductionTypes={deductionTypes}
                        />
                    </EditDialog>
                </Grid>
            </Grid>

        </Grid>
        <Grid item xs={12} sx={{mt: 1, ml: 1}}>
            <Typography
                variant="body2"
            >
                {deductionType.taxTreatment.description}
            </Typography>
        </Grid>
    </Grid>
        <Divider sx={{mt: 2, mb: 0}}/>
        <CloseDialogButton
            close={close}
        />
    </>
}
export default CustomDeductionTypeSelectEdit;