import {Button, Grid, Hidden, List, ListItem, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import styles from './Header.module.scss';
import {APP_LINK, MARKETING_LINK} from "../../common/AppLinks";

import logo from '../../images/logoTransparent.png';
import MobileMenu from "../MobileMenu";
import {useAuth0} from "@auth0/auth0-react";
import usePrimaryButton, {ActionButton} from "./usePrimaryButton";
import {App_Preference, useGetUserSettingLazyQuery, UserSettingType} from "../../graphql/generated/graphql";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import useSystemNotices from "../../Utils/useSystemNotices";

interface NavItemProps {
    to: string;
    children: React.ReactNode;
}

function NavItem({to, children}: NavItemProps) {
    return (
        <ListItem sx={{'& a': {textDecoration: 'none'}}}>
            <Link to={to}>
                {children}
            </Link>
        </ListItem>
    );
}

const Header = (props: any) => {
    const [showMobile, setShowMobile] = useState(false);
    let fixedHeaderStyle = props.fixHeader ? '' : 'fixedHeader';
    let headerClass = `${styles.header} ${fixedHeaderStyle}`;

    const {getPrimaryButtonDefinition} = usePrimaryButton();
    const {sendNotice} = useSystemNotices();

    const {isAuthenticated, isLoading, logout, loginWithPopup} = useAuth0();

    const [
        getAppPreference,
        {
            data: appPreferenceData,
            loading: appPreferenceLoading,
            error: appPreferenceError
        }] = useGetUserSettingLazyQuery({
        variables: {
            userSettingType: UserSettingType.AppPreference,
            defaultValue: App_Preference.PhpClient
        }
    });

    useEffect(() => {
        if (isAuthenticated && !appPreferenceData && !appPreferenceLoading && !appPreferenceError) {
            getAppPreference()
                .then(() => {/*do nothing.*/
                })
                .catch(error => {
                    sendNotice(`Error getting app preference: ${error.message}`)
                });
        }
    }, [isAuthenticated, appPreferenceLoading, appPreferenceError, appPreferenceData, getAppPreference, sendNotice]);

    const toggleMobileMenu = () => {
        setShowMobile(!showMobile);
    };
    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: process.env.REACT_APP_MARKETING_URL,
            },
        });
    };

    const handleLogin = () => {
        loginWithPopup();
    };


    useEffect(() => {
        if (!!appPreferenceError) {
            sendNotice(appPreferenceError.message);
        }
    }, [appPreferenceError, sendNotice]);


    let signedOutMenu = (
        <Hidden lgDown>
            <List>
                <NavItem to={`${MARKETING_LINK}/our-pay-stubs`}> Our Pay Stubs </NavItem>
                <NavItem to={`${MARKETING_LINK}/blog`}> Blog </NavItem>
                <NavItem to={`${MARKETING_LINK}/guide`}> Guide </NavItem>
                <NavItem to={`${MARKETING_LINK}/faq`}> FAQ </NavItem>
                <NavItem to={`${MARKETING_LINK}/pricing`}> Pricing </NavItem>
                <NavItem to={`${MARKETING_LINK}/contact-us`}> Contact </NavItem>
            </List>
        </Hidden>
    );

    const appPreference = appPreferenceData?.getUserSetting.value;
    const isLegacyUser = appPreference === App_Preference.PhpClient;

    let signedInMenu = (
        <Hidden lgDown>
            <List>
                {
                    isLegacyUser && (
                        <NavItem to={`${APP_LINK}`}> My pay stubs </NavItem>
                    )
                }
                <NavItem to={"/employees"}> My employees </NavItem>
                <NavItem to={`${APP_LINK}/account`}> My account </NavItem>
                <NavItem to={"/reports"}> Reports </NavItem>
                <NavItem to={`${MARKETING_LINK}/guide`}> Guide </NavItem>
                <NavItem to={`${MARKETING_LINK}/faq`}> FAQ </NavItem>
                <NavItem to={`${MARKETING_LINK}/pricing`}> Pricing </NavItem>
            </List>
        </Hidden>
    );

    if (isLoading) {
        return <Typography>Loading</Typography>;
    }

    const myAccountButton = (
        <Button
            onClick={handleLogin}
            variant={"outlined"}
            size={"large"}
            sx={{
                fontSize: "14px",
                m: 1,
                p: 2,
                '&:hover': {
                    transform: 'translateY(-2px)'
                }
            }}
        >
            Sign in
        </Button>
    );

    const logoutButton = <Button
        onClick={handleLogout}
        variant={"outlined"}
        size={"large"}
        sx={{
            fontSize: "14px",
            m: 1,
            p: 2,
            '&:hover': {
                transform: 'translateY(-2px)'
            }
        }}
    >
        Sign out
    </Button>;


    const getPrimaryMenuItem = (buttonDef: ActionButton) => {
        return <Button
            onClick={buttonDef.action}
            size={"large"}
            sx={{
                fontSize: "14px",
                m: 1,
                p: 2,
                backgroundImage: "linear-gradient(45deg, #3061F6, #00238c)",
                color: '#fff',
                transition: 'all 0.3s ease',
                '&:hover': {
                    backgroundImage: 'linear-gradient(45deg, #00238c, #3061F6)',
                    transform: 'translateY(-2px)',
                },
            }}
        >
            {buttonDef.label}
        </Button>;

    }

    return <>
        <Grid className={`${headerClass} `} container alignItems="center" justifyContent="space-between">
            <Grid item xs={4} lg={1}>
                <Link to={MARKETING_LINK}>
                    <img src={logo} alt={"OneTwoPay Logo"} width={125} height={125}/>
                </Link>
            </Grid>
            <Hidden mdDown>
                <Grid item xs={0} lg={7}>
                    <Grid container justifyContent={"center"} className={styles.mainMenu}>
                        {
                            (appPreferenceLoading) &&
                            <LoadingErrorDisplay
                                loading={appPreferenceLoading}
                            />
                        }
                        {isAuthenticated ? signedInMenu : signedOutMenu}
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden lgDown>
                <Grid item xs={0} lg={4} sx={{pt: 2}}
                      container display="flex"
                      alignItems="center"
                      className="justify-md-flex-space-between"
                      justifyContent="flex-start">
                    <Grid item xs={12}>
                        {isAuthenticated ? logoutButton : myAccountButton}
                        {getPrimaryMenuItem(getPrimaryButtonDefinition())}
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden lgUp>
                <Grid item xs={8} className="text-right">
                    <Grid className={`${styles.hamburger} ${showMobile ? 'show' : ''}`} onClick={toggleMobileMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </Grid>
                </Grid>
            </Hidden>
            <div onClick={(e) => e.stopPropagation()}>
                {showMobile &&
                    <MobileMenu
                        showMobile={showMobile}
                        setShowMobile={setShowMobile}
                        isLegacyUser={isLegacyUser}
                    />
                }
            </div>
        </Grid>
    </>
};

export default Header;

