import {Link} from "react-router-dom";
import {APP_LINK_CREATE, APP_LINK_PREVIEW} from "../../common/AppLinks";
import React from "react";
import {PayStub} from "../../graphql/generated/graphql";
import OneTwoPayTooltip from "../../common/OneTwoPayTooltip";

type ClassicViewLinkType = {
    payStub: PayStub
    calculateNeeded: boolean
}


const ClassicViewLink = (props: ClassicViewLinkType) => {
    const {payStub, calculateNeeded} = props;
    if (!payStub.id) {
        return <OneTwoPayTooltip
            tipContent={"Create your pay stub in the legacy app"}
            TooltipTarget={React.forwardRef<HTMLAnchorElement>((props, ref) => (
                <Link
                    {...props}
                    to={`${APP_LINK_CREATE}`}
                    ref={ref}
                >
                    Classic Pay Stub Create
                </Link>
            ))}
        />
    }
    if (!payStub.employerId || !payStub.employeeId || calculateNeeded) {
        return <OneTwoPayTooltip
            tipContent={"Finish this pay stub in the legacy app"}
            TooltipTarget={React.forwardRef<HTMLAnchorElement>((props, ref) => (
                <Link
                    {...props}
                    to={`${APP_LINK_CREATE}&step=generate&id=${payStub.id}`}
                    ref={ref}
                >
                    Classic Pay Stub Create
                </Link>
            ))}
        />
    }

    return <OneTwoPayTooltip
        tipContent={"View your pay stub in the legacy app"}
        TooltipTarget={React.forwardRef<HTMLAnchorElement>((props, ref) => (
            <Link
                {...props}
                to={`${APP_LINK_PREVIEW}${payStub.id}`}
                ref={ref}
            >
                Classic Pay Stub View
            </Link>
        ))}
    />

};

export default ClassicViewLink;