import {useParams} from "react-router-dom";
import {Typography} from "@mui/material";
import React, {useEffect} from "react";

type ParamsType = {
    redirect: string
}


const LegacyAppLogin = () => {
    const {redirect} = useParams<ParamsType>();

    useEffect(() => {
        if (!redirect) {
            window.location.href = `${process.env.REACT_APP_APP_URL}`;
            return;
        }
        window.location.href = `${process.env.REACT_APP_APP_URL}/${redirect}`;
    }, [redirect]);

    return <Typography
        variant="h3"
    >

        Signing in
    </Typography>
}
export default LegacyAppLogin;