import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import CloseDialogButton from "../../common/CloseDialogButton";
import * as React from "react";
import {useCallback, useState} from "react";
import {useSendEmailVerificationMutation} from "../../graphql/generated/graphql";
import {useAuth0} from "@auth0/auth0-react";
import useSystemNotices from "../../Utils/useSystemNotices";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import {useNavigate} from "react-router-dom";


const EmailVerificationDialog = () => {
    const {logout} = useAuth0();
    const {sendNotice} = useSystemNotices();
    const [sendVerificationSuccess, setSendVerificationSuccess] = useState(false);
    const navigate = useNavigate();

    const [error, setError] = useState<string>();

    const [
        sendEmailVerification,
        {
            loading: sendVerificationLoading,
            error: sendVerificationError
        }
    ] = useSendEmailVerificationMutation();

    const handleLogout = () => {
        sendNotice("EmailVerificationDialog triggered and user is closing.");
        logout({
            logoutParams: {
                returnTo: process.env.REACT_APP_MARKETING_URL,
            },
        });
    };

    const resendVerificationEmail = useCallback(() => {
        sendEmailVerification()
            .then(result => {
                if (!!result.data?.sendEmailVerification.successful) {
                    setSendVerificationSuccess(true);
                } else {
                    sendNotice(`Failed to trigger an email verification. Message: ${result.data?.sendEmailVerification.message}`);
                    setError("Failed to send verification email. Please contact support through the chat, by email to support@onetwopay.ca or by phone at 1 800 837 8793");
                }
            })
            .catch(error => {
                sendNotice(`Error trying to trigger an email verification. Message: ${error.message}`);
                setError(error.message);
            });
    }, [sendNotice, sendEmailVerification]);


    return <Dialog
        open={true}
    >
        <DialogTitle>
            <Typography
                variant={"h5"}
            >
                Email verification
            </Typography>
            <CloseDialogButton
                close={() => handleLogout()}
            />
        </DialogTitle>

        <DialogContent>
            {
                sendVerificationSuccess &&
                <Typography
                    variant={"body1"}
                >
                    Thank you! A new email verification request has been sent to your inbox.
                    <br/><br/>
                    You'll now be signed out but can sign back after verifying your email.
                </Typography>
            }
            {
                !sendVerificationSuccess &&
                <Typography
                    variant={"body1"}
                >
                    <b>Email not verified</b> - It looks like you are trying to access an account that is set up but
                    your email has not been
                    verified.
                    <br/><br/>
                    <b>Check your inbox</b> - Please verify your email by looking for a confirmation email from
                    OneTwoPay
                    and clicking "Confirm my account"
                    <br/><br/>
                    <b>Don't see that email?</b> - Check your spam folder and / or click the resend button below.
                    <br/><br/>
                    <b>Google sign-in</b> - Alternatively, you can sign out and then sign in using your Google account.
                </Typography>
            }
        </DialogContent>
        <DialogActions>
            {
                sendVerificationSuccess &&
                <Button
                    variant={"contained"}
                    size={"large"}
                    onClick={() => navigate("/employees")}
                >
                    Continue
                </Button>
            }
            {
                !sendVerificationSuccess &&
                <>
                    <Button
                        variant={"contained"}
                        size={'large'}
                        onClick={handleLogout}
                    >
                        Sign out
                    </Button>
                    <Button
                        variant={"contained"}
                        size={'large'}
                        onClick={resendVerificationEmail}
                    >
                        Resend
                    </Button>
                </>
            }
            <LoadingErrorDisplay
                loading={sendVerificationLoading}
                apolloError={sendVerificationError}
                stringError={error}
            />
        </DialogActions>
    </Dialog>
}
export default EmailVerificationDialog;