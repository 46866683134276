import React, {useCallback, useState} from "react";
import OneTwoPayTooltip from "../../common/OneTwoPayTooltip";
import {Button} from "@mui/material";
import useAnonCookie from "../../security/useAnonCookie";
import useSystemNotices from "../../Utils/useSystemNotices";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import {useResetPayStubMutation} from "../../graphql/generated/graphql";


type PropsType = {
    payStubId: number;
}

const ResetPayStubLink = (props: PropsType) => {

    const {payStubId} = props;


    const {getAnonUserId} = useAnonCookie();
    const {sendNotice} = useSystemNotices();
    const [error, setError] = useState<string>();

    const [
        resetPayStub,
        {
            loading: resetPayStubLoading,
            error: resetPayStubError
        }
    ] = useResetPayStubMutation();

    const resetPayStubNow = useCallback(() => {
        resetPayStub({
            variables: {
                payStubId: payStubId,
                anonUserId: getAnonUserId()
            }
        })
            .then(result => {
                const successful = result.data?.resetPayStub.successful;
                if (successful) {
                    // this is not ideal. Need to figure out how to do a reload of just the data that works well on ios.
                    // Clearing and reloading pay stub data in PayStubDisplayEdit seems to cause a problem in the Employee select edit. It makes all the buttons non-reactive to touch events.
                    window.location.reload();
                } else {
                    sendNotice(`Error resetting pay stub: ${result.data?.resetPayStub.message}`);
                    setError("Unable to reset pay stub. Please try again or contact support.");
                }
            })
            .catch(error => {
                sendNotice(`Error resetting pay stub: ${error.message}`);
                setError("Unable to reset pay stub. Please try again or contact support.");
            });

    }, [payStubId, sendNotice, resetPayStub, getAnonUserId]);

    return <>
        {
            !resetPayStubLoading && // preventing a console warning related to the Button unmounting before the tooltip.
            <OneTwoPayTooltip
                tipContent={"Clear all data in this pay stub and start fresh"}
                TooltipTarget={React.forwardRef<HTMLButtonElement>((props, ref) => (
                    <Button
                        {...props}
                        ref={ref}
                        variant="outlined"
                        onClick={() => resetPayStubNow()}
                        sx={{pl: 2, mb: 1}}
                    >
                        Reset pay stub
                    </Button>
                ))}
            />
        }
        {
            (error || resetPayStubError || resetPayStubLoading) &&
            <LoadingErrorDisplay
                loading={resetPayStubLoading}
                stringError={error}
                apolloError={resetPayStubError}
            />
        }
    </>


};

export default ResetPayStubLink;