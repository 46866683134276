import {gql} from "@apollo/client";
import {EMPLOYEE_FRAGMENT} from "./getEmployee";

const GET_EMPLOYEES = gql`
    query getEmployees($anonUserId: String!, $userId: Int) {
        getEmployees(anonUserId: $anonUserId, userId: $userId) {
            employees {
                ...employeeFields
            }
            userId
        }
    }
    ${EMPLOYEE_FRAGMENT}
`;

export default GET_EMPLOYEES;