import {gql} from "@apollo/client";


export const VACATION_SUMMARY_FRAGMENT = gql`
    fragment vacationSummaryFragment on VacationSummary {
        id
        previousPeriodEarned
        currentPeriodEarned
        ytdEarned
        previousPeriodUsedPaid
        currentPeriodUsedPaid
        ytdUsedPaid
        vacationAccrued
        payStubId
    }
`;

const GET_VACATION_SUMMARY = gql`

    query getVacationSummary($payStubId: Int!, $anonUserId: String!) {
        getVacationSummary(payStubId: $payStubId, anonUserId: $anonUserId) {
            ...vacationSummaryFragment
        }
    }
    ${VACATION_SUMMARY_FRAGMENT}
`;

export default GET_VACATION_SUMMARY;