import * as React from 'react';
import {ReactNode, useContext, useEffect, useState} from 'react';

import UserProfileDialog from "../common/UserProfileDialog";
import {REACT_CLIENT_APP_PREFERENCE, useSaveAppPreference} from "../common/useSaveAppPreference";
import {userContext} from "../components/User/UserContext";
import AnonymousDataTransferDialog from "../components/User/AnonymousDataTransferDialog";
import {useAuth0} from "@auth0/auth0-react";


type PropsType = {
    children: ReactNode;
}

const UserProfile = (props: PropsType) => {

    const [profileCreateRequired, setProfileCreateRequired] = useState<boolean>();
    const {user, refreshUser} = useContext(userContext);
    const [showDataTransferOption, setShowDataTransferOption] = useState<boolean>(false);
    const {isAuthenticated, isLoading} = useAuth0();

    const {saveAppPreference} = useSaveAppPreference();

    useEffect(() => {
        if (isLoading) {
            return;
        }
        if (!isAuthenticated) {
            setProfileCreateRequired(false);
            return;
        }
        if (!!user.email && !user.id) {
            setProfileCreateRequired(true);
        }
        if (!!user.email && !!user.id) {
            setProfileCreateRequired(false);
        }
    }, [user, isAuthenticated, isLoading]);

    if (profileCreateRequired === undefined) {
        return <></>;
    }

    if (profileCreateRequired) {
        return <UserProfileDialog
            userProfile={user}
            profileCreated={userProfile => {
                setShowDataTransferOption(true);
                setProfileCreateRequired(false);
                saveAppPreference(REACT_CLIENT_APP_PREFERENCE, userProfile.email);
            }}
        />
    }

    if (showDataTransferOption) {
        return <AnonymousDataTransferDialog
            close={() => {
                setShowDataTransferOption(false);
                refreshUser();
            }}
        />
    }

    return <>
        {props.children}
    </>
}
export default UserProfile;