import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {
    PHP_CLIENT_APP_PREFERENCE,
    REACT_CLIENT_APP_PREFERENCE,
    useSaveAppPreference
} from "../common/useSaveAppPreference";
import {
    App_Preference,
    GetUserSettingQuery,
    useGetUserSettingLazyQuery,
    UserSetting,
    UserSettingType
} from "../graphql/generated/graphql";
import {useAuth0} from "@auth0/auth0-react";
import useSystemNotices from "../Utils/useSystemNotices";
import CloseDialogButton from "../common/CloseDialogButton";

type PropsType = {
    closeAppPreferenceDialog: () => void;
}

function userSettingIsStale(userSetting: UserSetting) {
    if (userSetting.daysSinceLastUpdated === undefined || userSetting.daysSinceLastUpdated === null) {
        return true;
    }
    return userSetting.daysSinceLastUpdated > 30;
}

function appPreferenceIsLegacyAndStale(appPreference: GetUserSettingQuery | undefined): boolean {
    const userSetting = appPreference?.getUserSetting;
    if (!userSetting) {
        return false;
    }
    const appPreferenceIsStale = userSettingIsStale(userSetting);
    return appPreferenceIsStale && userSetting.value === App_Preference.PhpClient;
}

const AppPreferenceDialog = (props: PropsType) => {
    const {closeAppPreferenceDialog} = props;
    const {isAuthenticated} = useAuth0();
    const {sendNotice} = useSystemNotices();
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const [
        getAppPreference,
        {
            data: appPreferenceData,
            loading: appPreferenceLoading,
            error: appPreferenceError
        }] = useGetUserSettingLazyQuery({
        variables: {
            userSettingType: UserSettingType.AppPreference,
            defaultValue: App_Preference.PhpClient
        },
        fetchPolicy: "network-only"
    });

    useEffect(() => {
        if (isAuthenticated && !appPreferenceData && !appPreferenceLoading && !appPreferenceError) {
            getAppPreference()
                .then(result => {
                    setOpenDialog(appPreferenceIsLegacyAndStale(result.data));
                })
                .catch(error => {
                    sendNotice(`Error getting app preference: ${error.message}`)
                });
        }
    }, [isAuthenticated, appPreferenceLoading, appPreferenceError, appPreferenceData, getAppPreference, sendNotice]);
    const {saveAppPreference} = useSaveAppPreference();


    const updateAppPreference = useCallback((appPreference: string) => {
        saveAppPreference(appPreference, undefined);
        setOpenDialog(false);
        closeAppPreferenceDialog();
    }, [saveAppPreference, closeAppPreferenceDialog]);

    return <Dialog
        open={openDialog}
    >
        <DialogTitle>
            Pay stub generator preference
            <CloseDialogButton
                close={() => setOpenDialog(false)}
            />
        </DialogTitle>
        <DialogContent>
            Would you like to set the new pay stub generator as your default generator? You can always go back to the
            legacy generator if
            needed.
        </DialogContent>
        <DialogActions>

            <Button
                variant={'contained'}
                size={'large'}
                type={"button"}
                onClick={() => updateAppPreference(PHP_CLIENT_APP_PREFERENCE)}
            >
                No thanks. I prefer the legacy generator.
            </Button>

            <Button
                variant={'contained'}
                size={'large'}
                type={"button"}
                onClick={() => updateAppPreference(REACT_CLIENT_APP_PREFERENCE)}
            >
                Yes please!
            </Button>

        </DialogActions>
    </Dialog>
}
export default AppPreferenceDialog;