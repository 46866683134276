import {Box, Grid, SxProps, Theme, Typography} from "@mui/material";
import React from "react";
import {styled} from "@mui/system";


const GridPayStubTotals = styled(Grid)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
        width: 'auto',
    },
    alignItems: 'flex-end',
    [theme.breakpoints.up('sm')]: {
        alignItems: 'flex-start',
    },
}));

const SpanPayStubDisplayTotalsLabel = styled('span')(() => ({
    fontSize: '1.5rem',
    color: '#444444'
}))

const SpanPayStubDisplayTotalsAmount = styled('span')(() => ({
    marginLeft: '15px'
}))


type PropsType = {
    item1Label: string;
    item2Label: string;
    item3Label: string;
    item4Label: string;
    item1Amount: string | undefined;
    item2Amount: string | undefined;
    item3Amount: string | undefined;
    item4Amount: string | undefined;
    labelSx: SxProps<Theme>;
    amountSx: SxProps<Theme>;
}

const SummaryBox = (props: PropsType) => {
    const {
        item1Label,
        item2Label,
        item3Label,
        item4Label,
        item1Amount,
        item2Amount,
        item3Amount,
        item4Amount,
        labelSx,
        amountSx
    } = props;

    return <Box style={{
        backgroundColor: '#f4f4f4',
        padding: '16px',
    }}>
        <Grid container justifyContent="space-between" alignItems="flex-end" sx={{marginTop: 1}}>
            <GridPayStubTotals item xs={12} md={3}>
                <Typography sx={labelSx} noWrap>
                    <SpanPayStubDisplayTotalsLabel>{item1Label}</SpanPayStubDisplayTotalsLabel>
                </Typography>
                <Typography sx={amountSx}>
                    <SpanPayStubDisplayTotalsAmount>{item1Amount}</SpanPayStubDisplayTotalsAmount>
                </Typography>

            </GridPayStubTotals>
            <GridPayStubTotals item xs={12} md={3}>
                <Typography sx={labelSx}>
                    <SpanPayStubDisplayTotalsLabel>{item2Label}</SpanPayStubDisplayTotalsLabel>
                </Typography>
                <Typography sx={amountSx}>
                    <SpanPayStubDisplayTotalsAmount>{item2Amount}</SpanPayStubDisplayTotalsAmount>
                </Typography>
            </GridPayStubTotals>
            <GridPayStubTotals item xs={12} md={3}>
                <Typography sx={labelSx}>
                    <SpanPayStubDisplayTotalsLabel>{item3Label}</SpanPayStubDisplayTotalsLabel>
                </Typography>
                <Typography sx={amountSx}>
                    <SpanPayStubDisplayTotalsAmount> {item3Amount}</SpanPayStubDisplayTotalsAmount>
                </Typography>
            </GridPayStubTotals>
            <GridPayStubTotals item xs={12} md={3}>
                <Typography sx={labelSx}>
                    <SpanPayStubDisplayTotalsLabel>{item4Label}</SpanPayStubDisplayTotalsLabel>
                </Typography>
                <Typography sx={amountSx}>
                    <SpanPayStubDisplayTotalsAmount> {item4Amount}</SpanPayStubDisplayTotalsAmount>
                </Typography>
            </GridPayStubTotals>
        </Grid>
    </Box>
}
export default SummaryBox;