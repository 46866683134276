import {Control} from "react-hook-form/dist/types";
import {FormControlLabel, Switch, SxProps, Theme} from "@mui/material";
import {Controller, FieldValues, Path} from "react-hook-form";
import React from "react";


type PropsType<TFormValues extends FieldValues> = {
    fieldName: keyof TFormValues;
    label: string;
    control: Control<TFormValues>;
    valueChanged?: () => void;
    sx?: SxProps<Theme> | undefined;

}

const ReactFormSwitch = <TFormValues extends FieldValues>(props: PropsType<TFormValues>) => {

    const {control, label, fieldName, valueChanged, sx} = props;
    return <Controller
        name={fieldName as Path<TFormValues>}
        control={control}
        render={({field: {onChange, ref, value, ...rest}}) => (
            <FormControlLabel
                control={
                    <Switch
                        {...rest}
                        onChange={(event) => {
                            onChange(event.target.checked);
                            !!valueChanged && valueChanged();
                        }}
                        checked={value}
                        inputRef={ref}
                    />
                }
                label={label}
                sx={sx}
            />
        )}
    />
}

export default ReactFormSwitch;