import React, {useCallback, useContext, useEffect, useState} from "react";
import {
    Employee,
    PayStubBasic,
    useDeleteEmployeeMutation,
    useGetEmployeesLazyQuery,
    useGetPayStubsForEmployeeLazyQuery
} from "../graphql/generated/graphql";
import LoadingErrorDisplay from "../common/LoadingErrorDisplay";
import useAnonCookie from "../security/useAnonCookie";
import {DataGrid, GridCellParams, GridColDef} from "@mui/x-data-grid";
import ResponsiveEditButton from "../components/ResponsiveEditButton";
import {QuickSearchToolbar} from "../components/Report/EmployeesSelect";
import EditEmployee from "../components/Employee/EditEmployee";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import useViewerStyle from "../components/PayStub/useViewerStyle";
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import SimplePayStubList from "../components/DataGrids/SimplePayStubList";
import {Link, useNavigate} from "react-router-dom";
import useSystemNotices from "../Utils/useSystemNotices";
import SuccessMessageDisplay from "../common/SuccessMessageDisplay";
import DeleteConfirmDialog from "../common/DeleteConfirmDialog";
import {getNumber} from "../Utils/stringMath";
import {assumedUserContext} from "../components/User/AssumedUserContext";
import {makeStyles} from "@mui/styles";
import OneTwoPayRotateScreenInstruction from "../common/OneTwoPayRotateScreenInstruction";
import CloseDialogButton from "../common/CloseDialogButton";
import OneTwoPayDeleteIconWithTooltip from "../common/OneTwoPayDeleteIconWithTooltip";


const useStyles = makeStyles(() => ({
    dialogContent: {
        padding: 3,
    },
    paper: {
        marginLeft: '10px',
        marginRight: '8px',
        margin: 0,
        width: '100%'
    },
}));

type EmployeeOptionRow = {
    id: number;
    name: string;
    address: string;
}

type MyColDef = GridColDef & {
    field: 'id' | 'name' | 'address' | 'showPayStubs' | 'actions',
}

interface MyGridCellParams extends GridCellParams {
    row: EmployeeOptionRow;
    id: any
}

function getColumnsForDataGrid(setEditEmployeeId: (employeeId: number) => void,
                               viewPayStubs: (employeeId: number) => void,
                               deleteEmployee: (employeeId: number) => void,
                               isScreenWide: boolean
): MyColDef[] {
    const nameColumn: MyColDef = {
        field: 'name',
        headerName: 'Name / ID',
        flex: 1,
        cellClassName: 'cell',
        renderCell: (params: MyGridCellParams) => {
            return (
                <Link
                    to={"/employees"}
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        viewPayStubs(params.id);
                    }}
                >
                    {params.row.name}
                </Link>
            );
        }
    };
    const addressColumn: MyColDef = {field: 'address', headerName: 'Address', flex: 1, cellClassName: 'cell'};
    const showPayStubsColumn: MyColDef = {
        field: 'showPayStubs',
        headerName: 'View pay stubs',
        flex: .5,
        cellClassName: 'cell',
        renderCell: (params: { id: any; }) => {
            return <ResponsiveEditButton
                buttonLabel={"View pay stubs"}
                editAction={event => {
                    event.stopPropagation();
                    viewPayStubs(params.id);
                }}
                editIcon={<AutoAwesomeMotionIcon/>}
            />;
        }
    };
    const actionsColumn: MyColDef = {
        field: 'actions',
        headerName: 'Actions',
        flex: .5,
        sortable: false,
        cellClassName: 'cell',
        renderCell: (params: MyGridCellParams) => {
            return (
                <div>
                    <ResponsiveEditButton
                        buttonLabel={"Edit Employee"}
                        editAction={event => {
                            event.stopPropagation();
                            setEditEmployeeId(params.id);
                        }}
                    />
                    <OneTwoPayDeleteIconWithTooltip
                        toolTip={'Delete employee'}
                        deleteAction={() => deleteEmployee(getNumber(params.row.id))}
                        sx={{ml: 2}}
                        iconSx={{fontSize: '2.5rem'}}
                    />
                </div>
            )
        }
    };
    const columnDefinitions: MyColDef[] = [
        nameColumn,
        actionsColumn
    ];

    if (isScreenWide) {
        columnDefinitions.splice(1, 0, addressColumn, showPayStubsColumn);
    }
    return columnDefinitions;

}

type OptionRow = {
    id: string;
    name: string;
    address: string;
}


function getEmployeeDataForDataGrid(employeesData: Employee[]) {
    return employeesData
        .filter(employee => !employee.deleted)
        .map((employee: Employee) => {
                let optionRow: OptionRow = {
                    id: employee.id.toString(),
                    name: employee.detailedName,
                    address: employee.address1 || "",
                };
                return optionRow
            }
        )
        .sort((option1: OptionRow, option2: OptionRow) => {
            let text2Upper = option2.name.toUpperCase();
            let text1Upper = option1.name.toUpperCase();
            return text1Upper < text2Upper ? -1 : text1Upper > text2Upper ? 1 : 0;
        });
}


function getEmployeeById(employeeId: number, employeesData: Employee[] | undefined) {
    if (!employeeId || !employeesData) {
        return undefined;
    }
    return employeesData.find(employee => {
        return Number(employee.id) === Number(employeeId);
    });
}

function sortPayStubs(employeePayStubs: Array<PayStubBasic>): Array<PayStubBasic> {

    const payStubsCopy = [...employeePayStubs];
    payStubsCopy.sort((option1, option2) => {
        return option1.payDate < option2.payDate ? -1 : option1.payDate > option2.payDate ? 1 : 0;
    });
    return payStubsCopy;

}

function getEmployeeFromId(employeeId: number | undefined, employeesData: Array<Employee> | undefined) {
    if (!employeeId || !employeesData) {
        return undefined;
    }
    return employeesData.find(employee => employee.id === employeeId);
}

function getDeleteEmployeeDescription(deleteEmployeeId: number | undefined,
                                      employeesData: Array<Employee> | undefined,
                                      sendNotice: (message: string) => void
) {
    const deleteEmployeeObject: Employee | undefined = getEmployeeFromId(deleteEmployeeId, employeesData);
    if (!!deleteEmployeeId && !deleteEmployeeObject) {
        sendNotice(`deleteEmployeeObject not defined in Employees. This should not happen. Employee ID: ${deleteEmployeeId}`);
    }
    return !!deleteEmployeeObject ? deleteEmployeeObject.detailedName : deleteEmployeeId;
}

const Employees = () => {

    const classes = useStyles();
    const {assumedUser} = useContext(assumedUserContext);
    const [editEmployeeId, setEditEmployeeId] = useState<number>();
    const [employeePayStubs, setEmployeePayStubs] = useState<Array<PayStubBasic>>();
    const [viewingEmployee, setViewingEmployee] = useState<Employee>();
    const [error, setError] = useState<string | null | undefined>();
    const [employees, setEmployees] = useState<Array<Employee>>();
    const [deleteEmployeeId, setDeleteEmployeeId] = useState<number>();
    const [deleteSuccessMessage, setDeleteSuccessMessage] = useState<string | null | undefined>();

    const {isScreenWide} = useViewerStyle();
    const navigate = useNavigate();
    const {sendNotice} = useSystemNotices();
    const {getAnonUserId} = useAnonCookie();

    const [
        deleteEmployee,
        {
            loading: deleteEmployeeLoading,
            error: deleteEmployeeError
        }
    ] = useDeleteEmployeeMutation();

    const anonUserId = getAnonUserId();
    const [
        getEmployees,
        {
            data: employeesData,
            loading: employeesLoading,
            error: employeesError
        }] = useGetEmployeesLazyQuery({
        variables: {
            anonUserId: anonUserId,
            userId: assumedUser.id
        },
        fetchPolicy: "no-cache"
    });

    const [
        getPayStubsForEmployee,
        {
            loading: payStubsForEmployeeLoading,
            error: payStubsForEmployeeError
        }] = useGetPayStubsForEmployeeLazyQuery();

    const getEmployeesNow = useCallback(() => {
        getEmployees()
            .then(() => {
                // do nothing. handled in useEffect.
            });
    }, [getEmployees]);

    useEffect(() => {
        if (!employeesData && !employeesLoading && !employeesError) {
            getEmployeesNow();
        }
    }, [getEmployeesNow, employeesData, employeesLoading, employeesError]);

    useEffect(() => {
        const employees = employeesData?.getEmployees?.employees;
        if (!!employees && employees.length > 0) {
            console.log("setting employees");
            setEmployees(employees);
            return;
        }
        if (!!employeesData && (!employees || employees?.length === 0)) {
            navigate("/pay-stub");
        }
    }, [employeesData, navigate]);

    const deleteEmployeeOnServer = useCallback((employeeId: number) => {
        deleteEmployee({
            variables: {
                employeeId: employeeId
            },
        })
            .then(result => {
                if (!result.data?.deleteEmployee.successful) {
                    sendNotice(`Error deleting employee: ${result.data?.deleteEmployee.message}`);
                    setError(result.data?.deleteEmployee.message);
                } else {
                    setDeleteSuccessMessage("Employee deleted. This employee will no longer be included in reports or search results.");
                    getEmployeesNow();
                }
                setDeleteEmployeeId(undefined);
            })
            .catch(error => {
                sendNotice(`Error deleting employee: ${error.message}`);
                setError("Error deleting employee. Please try again or contact support.");
                setDeleteEmployeeId(undefined);
            });
    }, [deleteEmployee, sendNotice, getEmployeesNow]);

    const loadPayStubsForEmployee = useCallback((employee: Employee) => {
        if (!employee) {
            sendNotice("Trying to view employee pay stubs but viewing employee not set.");
            return;
        }
        getPayStubsForEmployee({
            variables: {
                anonUserId: anonUserId,
                employeeId: employee.id
            },
            fetchPolicy: "no-cache"
        })
            .then(result => {
                const employeePayStubs = result.data?.getPayStubsForEmployee;
                if (!!employeePayStubs && employeePayStubs.length > 0) {
                    setEmployeePayStubs(sortPayStubs(employeePayStubs));
                } else if (employeePayStubs && employeePayStubs.length === 0) {
                    setError(`No pay stubs found for ${employee.detailedName}`)
                } else {
                    setError("Error retrieving pay stubs. Please try again or contact support.");
                }

            })
            .catch(error => setError(error.message));
    }, [anonUserId, getPayStubsForEmployee, sendNotice]);

    const openPayStubsForEmployee = useCallback((employeeId: number) => {
        setError(undefined);
        let employeeById = getEmployeeById(employeeId, employees);
        if (!employeeById) {
            sendNotice("Action required: getEmployeeById did not return an employee. That should not happen.");
            return;
        }
        setViewingEmployee(employeeById);
        loadPayStubsForEmployee(employeeById);
    }, [loadPayStubsForEmployee, employees, sendNotice]);

    if (employeesLoading || employeesError) {
        return <LoadingErrorDisplay
            loading={employeesLoading}
            apolloError={employeesError}
        />
    }

    const marginLeft = isScreenWide ? "20px" : "0px";

    const deleteEmployeeDescription = getDeleteEmployeeDescription(deleteEmployeeId, employees, sendNotice);

    function closeEmployeePayStubsDialog() {
        setViewingEmployee(undefined);
        setEmployeePayStubs(undefined);
    }

    return <div
        style={{display: "flex", minHeight: "500px", maxWidth: "1500px", marginTop: "10px", marginLeft: marginLeft}}>
        <div style={{margin: 'auto', width: '90%'}}>

            {
                !!employees &&
                <>
                    <OneTwoPayRotateScreenInstruction/>
                    <DataGrid
                        rows={getEmployeeDataForDataGrid(employees)}
                        columns={getColumnsForDataGrid(setEditEmployeeId, openPayStubsForEmployee, setDeleteEmployeeId, isScreenWide)}
                        slots={{toolbar: QuickSearchToolbar}}
                        pageSizeOptions={[5, 10, 25]}
                        initialState={{
                            pagination: {paginationModel: {pageSize: 10}},
                        }}
                        sx={{
                            '& .MuiDataGrid-cell': {
                                fontSize: '1.25rem',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                fontSize: '1.25rem',
                            },
                            '& .MuiDataGrid-footerContainer': {
                                fontSize: '1.25rem',
                            },
                            '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows, & .MuiTablePagination-select, & .MuiTablePagination-actions': {
                                fontSize: '1.25rem',
                            },
                        }}
                    />
                </>
            }
            {
                !!editEmployeeId &&
                <Dialog
                    open={!!editEmployeeId}
                >
                    <DialogTitle>
                        <CloseDialogButton
                            close={() => setEditEmployeeId(undefined)}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <EditEmployee
                            employeeId={editEmployeeId}
                            close={() => {
                                setEditEmployeeId(undefined);
                            }}
                            employeeAddedEdited={() => {
                                getEmployeesNow();
                                setEditEmployeeId(undefined);
                            }}
                        />
                    </DialogContent>

                </Dialog>
            }
            {
                !!employeePayStubs && employeePayStubs.length > 0 && viewingEmployee &&
                <Dialog
                    PaperProps={{className: classes.paper}}
                    fullWidth
                    maxWidth={'md'}
                    open={true}
                >
                    <DialogTitle>
                        <CloseDialogButton
                            close={closeEmployeePayStubsDialog}
                        />
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <SimplePayStubList
                            rows={employeePayStubs}
                            employeeName={viewingEmployee.detailedName}
                            reload={() => loadPayStubsForEmployee(viewingEmployee)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={closeEmployeePayStubsDialog}
                        >
                            Close
                        </Button>
                    </DialogActions>

                </Dialog>
            }
            {
                (!!error || payStubsForEmployeeLoading || payStubsForEmployeeError) &&
                <LoadingErrorDisplay
                    apolloError={payStubsForEmployeeError}
                    loading={payStubsForEmployeeLoading}
                    stringError={error}
                />
            }
            {
                (deleteEmployeeLoading || !!deleteEmployeeError || !!error) &&
                <LoadingErrorDisplay
                    loading={deleteEmployeeLoading}
                    apolloError={deleteEmployeeError}
                    stringError={error}
                />
            }
            {
                !!deleteSuccessMessage &&
                <SuccessMessageDisplay messages={deleteSuccessMessage}/>
            }
            {
                !!deleteEmployeeId &&
                <DeleteConfirmDialog
                    itemDescription={`employee: ${deleteEmployeeDescription}`}
                    confirmAction={() => deleteEmployeeOnServer(deleteEmployeeId)}
                    cancelAction={() => setDeleteEmployeeId(undefined)}
                />
            }
        </div>
    </div>

}

export default Employees;