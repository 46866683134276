import {useForm} from "react-hook-form";
import {PayPeriod} from "../../../graphql/generated/graphql";
import {Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Typography} from "@mui/material";
import React from "react";
import ReactHookFormDatePicker from "../../../common/ReactHookFormDatePicker";
import {DATE_FORMAT, getStringFromDate} from "../../../Utils/dateFormatter";
import dayjs from "dayjs";
import OneTwoPayNumericTextField from "../../../common/OneTwoPayNumericTextField";
import CloseDialogButton from "../../../common/CloseDialogButton";

type PropsType = {
    payPeriod?: PayPeriod | undefined;
    updatePayPeriod: (payPeriod: PayPeriod) => void;
    close: () => void;
}


function getDefaultPayPeriod() {
    return {
        id: "0",
        amount: "0",
        startDate: getStringFromDate(dayjs(), DATE_FORMAT),
        endDate: getStringFromDate(dayjs(), DATE_FORMAT)
    };
}

const RoePayPeriod = (props: PropsType) => {
    const {updatePayPeriod, close, payPeriod} = props;
    const {
        control,
        register,
        getValues,
        formState: {errors},
        watch
    } = useForm<PayPeriod>({
        defaultValues: payPeriod || getDefaultPayPeriod()
    });

    function savePayPeriod() {
        updatePayPeriod(getValues());
        close();
    }

    const amountValue = watch("amount");

    return <Dialog
        open={true}
        fullWidth={true}
    >
        <DialogTitle>
            <CloseDialogButton
                close={close}
            />
        </DialogTitle>
        <DialogContent>
            <form noValidate onSubmit={e => {
                savePayPeriod();
                e.preventDefault();
                e.stopPropagation();
            }
            }>
                <Typography variant={"h6"}>Add pay period</Typography>
                <Grid container spacing={2} sx={{mt: 1}}>
                    <Grid container item xs={12} spacing={2} sx={{mt: 1}}>
                        <Grid item xs={12}>
                            <OneTwoPayNumericTextField
                                label={"Amount"}
                                amount={amountValue}
                                {...register(`amount`)}
                                error={!!errors.amount}
                                errorText={errors.amount?.message}
                                variant={"outlined"}

                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ReactHookFormDatePicker
                                fieldName={`startDate`}
                                label={"Start date"}
                                control={control}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ReactHookFormDatePicker
                                fieldName={`endDate`}
                                label={"End date"}
                                control={control}
                                required={true}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{borderColor: 'grey', borderWidth: '.5px', width: '100%', mt: 2}}/>
                    <Grid item xs={6}>
                        <Button
                            variant={"contained"}
                            style={{fontSize: '1.2rem'}}
                            color={"error"}
                            onClick={() => close()}
                        >
                            {"Cancel"}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant={"contained"}
                            type={"submit"}
                            style={{fontSize: '1.2rem'}}
                        >
                            Save pay period
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </DialogContent>
    </Dialog>
}
export default RoePayPeriod;