import {Box, Button, Grid, Typography} from "@mui/material";
import React from "react";

type PropsType = {
    message: string;
    triggerAction: () => void;
    actionLabel: string;
}

const PopUpBlockerHandler = (props: PropsType) => {
    const {message, triggerAction, actionLabel} = props;

    if (!message) {
        return <></>;
    }
    return <Grid
        container
        justifyContent='center'
        alignItems='center'
        style={{minHeight: '30vh'}}
    >
        <Grid item xs={12} sm={8} md={6} lg={4}>
            <Box
                sx={{

                    maxWidth: '800px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: "30px",
                    paddingRight: "40px"
                }}>
                <Typography
                    variant={"body1"}
                >
                    {message}
                </Typography>
                <Button
                    sx={{mt: 3, fontSize: '2rem'}}
                    variant="contained"
                    size={"large"}
                    onClick={triggerAction}

                >
                    {actionLabel}
                </Button>
            </Box>
        </Grid>
    </Grid>
}
export default PopUpBlockerHandler;