import React, {useCallback, useState} from "react";
import {Employer, useSaveLogoToEmployerMutation} from "../../graphql/generated/graphql";
import useAnonCookie from "../../security/useAnonCookie";
import usePayStubIdManager from "../PayStub/usePayStubIdManager";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import CloudinaryUploadWidget from "../CloudinaryUploadWidget";
import useCloudinaryLibrary from "../PayStub/useCloudinaryLibrary";
import YesOrNoDialog from "../../common/YesOrNoDialog";
import useSystemNotices from "../../Utils/useSystemNotices";

type PropsType = {
    logoUpdated: (employerId: number) => void;
    editLogo: boolean;
    setEditLogo: (editLogo: boolean) => void;
    employer: Employer
}

const AddEditLogo = (props: PropsType) => {
    const {logoUpdated, editLogo, setEditLogo, employer} = props;
    const {getAnonUserId} = useAnonCookie();
    const {getPayStubId} = usePayStubIdManager();
    const {sendNotice} = useSystemNotices();
    const [uploadError, setUploadError] = useState("");
    const [applyToExistingPayStubs, setApplyToExistingPayStubs] = useState<boolean>();

    const {
        loaded: cloudinaryLibraryLoaded,
        uwConfig
    } = useCloudinaryLibrary();

    const [
        saveLogoToEmployer,
        {
            loading: saveLogoLoading,
            error: saveLogoError
        }
    ] = useSaveLogoToEmployerMutation();

    const saveLogoIfPublicIdAndEmployerId = useCallback((logoPublicId: string | undefined, employerId: number) => {
        if (!logoPublicId) {
            sendNotice("Cannot save logo. Logo public ID missing");
            setUploadError("Error saving logo. Please try again or contact support.");
            return;
        }
        if (!employerId) {
            sendNotice("Cannot save logo. Employer ID missing");
            setUploadError("Error saving logo. Employer missing. Please try again or contact support.");
            return
        }
        if (applyToExistingPayStubs === undefined) {
            sendNotice("Cannot save logo. applyToExistingPayStubs is missing.");
            setUploadError("Error saving logo. Please try again or contact support.");
            return;
        }
        saveLogoToEmployer({
            variables: {
                input: {
                    employerId: employerId,
                    logoPublicId: logoPublicId
                },
                payStubId: getPayStubId(),
                applyToExistingPayStubs: applyToExistingPayStubs,
                anonUserId: getAnonUserId()
            },
        })
            .then(result => {
                if (result.data && result.data.saveLogoToEmployer.successful) {
                    let savedEmployerId = result.data.saveLogoToEmployer.employerId;
                    logoUpdated(savedEmployerId);
                }
            });
    }, [applyToExistingPayStubs, sendNotice, saveLogoToEmployer, getPayStubId, getAnonUserId, logoUpdated]);

    if (applyToExistingPayStubs === undefined) {
        return <YesOrNoDialog
            question={"Would you like to create a new version of this employer? If not, the new logo will be added to some or all of the previous pay stubs for this employer. Note that creating a new version does not affect reports."}
            handleBooleanAnswer={setApplyToExistingPayStubs}
            yesLabel={"No. Update existing version."}
            noLabel={"Yes. Create a new version."}
            cancel={() => setEditLogo(false)}
        />
    }

    return <>
        {
            (!!uploadError || !cloudinaryLibraryLoaded) &&
            <LoadingErrorDisplay stringError={uploadError} loading={!cloudinaryLibraryLoaded}/>
        }
        {
            (!!saveLogoError || saveLogoLoading) &&
            <LoadingErrorDisplay
                apolloError={saveLogoError}
                loading={saveLogoLoading}
            />
        }
        {
            cloudinaryLibraryLoaded &&
            <CloudinaryUploadWidget
                uwConfig={uwConfig}
                setPublicId={(logoPublicId) => saveLogoIfPublicIdAndEmployerId(logoPublicId, employer.id)}
                uploadError={setUploadError}
                open={editLogo}
                close={() => setEditLogo(false)}
            />
        }
    </>
}
export default AddEditLogo;