import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import CloseDialogButton from "./CloseDialogButton";

type PropsType = {
    statement: React.ReactElement
    close: () => void;
}

const GotItDialog = (props: PropsType) => {
    const {statement, close} = props;
    return <Dialog
        open={!!statement}
    >
        <DialogTitle>
            <CloseDialogButton
                close={close}
            />
        </DialogTitle>
        <DialogContent>
            {statement}
        </DialogContent>
        <DialogActions>
            <Button
                variant={'contained'}
                size={'large'}
                type={"button"}
                onClick={close}
            >
                Got it!
            </Button>
        </DialogActions>
    </Dialog>
}
export default GotItDialog;