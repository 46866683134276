import useGetProvinces from "../Utils/useGetProvinces";
import LoadingErrorDisplay from "./LoadingErrorDisplay";
import React, {ReactNode} from "react";
import {ClaimCode, PayFrequency, Province} from "../graphql/generated/graphql";
import useGetPayFrequencies from "../Utils/useGetPayFrequencies";
import useGetClaimCodes from "../Utils/useGetClaimCodes";


type EnumsProvided = {
    provinces: Province[],
    payFrequencies: PayFrequency[],
    claimCodes: ClaimCode[]

}
type PropsType = {
    children: (data: EnumsProvided) => ReactNode;
}

const EnumSupplier = (props: PropsType) => {
    const {children} = props;

    const {
        provinces,
        error: provincesError,
        loading: provincesLoading
    } = useGetProvinces();

    const {
        payFrequencies,
        error: payFrequenciesError,
        loading: payFrequenciesLoading
    } = useGetPayFrequencies();

    const {
        claimCodes,
        error: claimCodesError,
        loading: claimCodesLoading
    } = useGetClaimCodes();


    if (!provinces || !payFrequencies || !claimCodes) {
        return <LoadingErrorDisplay
            loading={provincesLoading || payFrequenciesLoading || claimCodesLoading}
            apolloError={provincesError || payFrequenciesError || claimCodesError}
        />
    }


    return <>{children({provinces, payFrequencies, claimCodes})}</>;
}
export default EnumSupplier;