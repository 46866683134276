import {Button} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {Employer, GetEmployerQuery, useGetEmployerLazyQuery} from "../../graphql/generated/graphql";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import EmployerSelectEdit from "./EmployerSelectEdit";
import useAnonCookie from "../../security/useAnonCookie";
import EmployerDisplayWithLogo from "./EmployerDisplayWithLogo";
import {getNumber} from "../../Utils/stringMath";
import useSystemNotices from "../../Utils/useSystemNotices";


function newEmployerDataNeeded(employerId: number | undefined | null, employerData: GetEmployerQuery | undefined) {
    const employerIdExists = !!employerId && getNumber(employerId) > 0;
    const employerDataCurrent = !!employerData && employerData.getEmployer.id === employerId;
    return employerIdExists || !employerDataCurrent;
}

type PropsType = {
    employerId: number | undefined | null;
    employerUpdated: (employerId: number) => void;
    isEditable: boolean;
    clearEmployer: () => void;
}

const EmployerDisplayEdit = (props: PropsType) => {
    const {employerId, employerUpdated, isEditable, clearEmployer} = props;
    const [editMode, setEditMode] = useState(false);
    const [employer, setEmployer] = useState<Employer>();
    const {sendNotice} = useSystemNotices();
    const {getAnonUserId} = useAnonCookie();

    const [
        getEmployer,
        {
            data: employerData,
            loading: employerLoading,
            error: employerError
        }
    ] = useGetEmployerLazyQuery();

    const getEmployerFromServer = useCallback((updatedEmployerId: number, fetchPolicy: "network-only" | "cache-first") => {
        if (!updatedEmployerId) {
            return;
        }
        getEmployer({
            variables: {
                employerId: updatedEmployerId,
                anonUserId: getAnonUserId()
            },
            fetchPolicy: fetchPolicy
        })
            .then(result => {
                setEmployer(result.data?.getEmployer)
            })
            .catch(error => {
                console.error(`Error getting employer: ${error.message}`);
                sendNotice(`Error getting employer for ID: ${updatedEmployerId}. Error message: ${error.message}`);
            });
    }, [sendNotice, getAnonUserId, getEmployer]);

    useEffect(() => {
        if (!!employerId && newEmployerDataNeeded(employerId, employerData) && !employerLoading && !employerError) {
            getEmployerFromServer(employerId, "cache-first");
        }
    }, [employerId, getEmployerFromServer, employerData, employerLoading, employerError]);

    useEffect(() => {
        if (!employerId && !!employer) {
            setEmployer(undefined);
        }
    }, [employer, employerId]);

    if (editMode) {
        return <EmployerSelectEdit
            close={() => setEditMode(false)}
            employer={employer}
            employerUpdated={employerUpdated}
            isEditable={isEditable}
            clearEmployer={clearEmployer}
        />;
    }
    const noEmployerDisplay: ReactJSXElement = <Button
        type={"button"}
        variant="contained"
        size="large"
        onClick={() => setEditMode(true)}
    >
        Add employer
    </Button>;

    return <EmployerDisplayWithLogo
        employer={employer}
        edit={() => setEditMode(true)}
        noEmployerDisplay={noEmployerDisplay}
        employerUpdated={employerId => {
            employerUpdated(employerId);
            getEmployerFromServer(employerId, "network-only");
        }}
        isEditable={isEditable}
    />;
}
export default EmployerDisplayEdit;