import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import React from "react";
import EditEmployerForm from "./EditEmployerForm";
import {Employer} from "../../graphql/generated/graphql";
import CloseDialogButton from "../../common/CloseDialogButton";

type PropsType = {
    close: () => void;
    employer: Employer;
}
const EditEmployerDialog = (props: PropsType) => {

    const {close, employer} = props;

    return <Dialog open={true}
                   sx={{
                       mt: 5,
                       '& .MuiDialog-paper': {
                           minWidth: {xs: '95vw', sm: '80vw', md: '65vw', lg: '90vw'},
                           minHeight: '400px',
                       },
                   }}>
        <DialogTitle>
            <CloseDialogButton
                close={close}
            />
        </DialogTitle>
        <DialogContent>
            <EditEmployerForm
                close={close}
                employer={employer}
                employerUpdated={() => {
                    close();
                }}
            />
        </DialogContent>
    </Dialog>;
}

export default EditEmployerDialog;