import {Box, Grid, Typography} from "@mui/material";
import React from "react";
import {VacationSummary} from "../../graphql/generated/graphql";
import {styled} from "@mui/system";
import {getNumber} from "../../Utils/stringMath";

const GridPayStubTotals = styled(Grid)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'flex-end',
    marginTop: 2,
}));

const SpanPayStubDisplayTotalsLabel = styled('span')(() => ({
    fontSize: '1.5rem',
    color: '#444444'
}))

const SpanPayStubDisplayTotalsAmount = styled('span')(() => ({
    marginLeft: '15px'
}))


type PropsType = {
    vacationPay: VacationSummary;
}

const VacationPayDisplay = (props: PropsType) => {

    const {vacationPay} = props;

    return <Box style={{
        backgroundColor: '#f4f4f4',
        padding: '16px',
    }}>
        <Grid container direction={"row"}>
            <Grid item xs={12}>

                <Grid container
                      justifyContent="space-between"
                      alignItems="flex-end"
                      sx={{marginTop: 1}}
                      columnSpacing={5}
                      rowSpacing={0}
                >
                    <GridPayStubTotals item xs={12} sm={6}>
                        <Typography sx={{fontWeight: '500', marginRight: "8px"}} noWrap>
                            <SpanPayStubDisplayTotalsLabel>Current earned</SpanPayStubDisplayTotalsLabel>
                        </Typography>
                        <Typography sx={{fontWeight: '500'}}>
                            <SpanPayStubDisplayTotalsAmount>${getNumber(vacationPay.currentPeriodEarned).toFixed(2)}</SpanPayStubDisplayTotalsAmount>
                        </Typography>

                    </GridPayStubTotals>
                    <GridPayStubTotals item xs={12} sm={6}>
                        <Typography sx={{fontWeight: '500', marginRight: "8px"}}>
                            <SpanPayStubDisplayTotalsLabel>YTD earned</SpanPayStubDisplayTotalsLabel>
                        </Typography>
                        <Typography sx={{fontWeight: '500'}}>
                            <SpanPayStubDisplayTotalsAmount>${getNumber(vacationPay.ytdEarned).toFixed(2)}</SpanPayStubDisplayTotalsAmount>
                        </Typography>
                    </GridPayStubTotals>
                    <GridPayStubTotals item xs={12} sm={6}>
                        <Typography sx={{fontWeight: '500', marginRight: "8px"}}>
                            <SpanPayStubDisplayTotalsLabel>Current used / paid</SpanPayStubDisplayTotalsLabel>
                        </Typography>
                        <Typography sx={{fontWeight: '500'}}>
                            <SpanPayStubDisplayTotalsAmount> ${getNumber(vacationPay.currentPeriodUsedPaid).toFixed(2)}</SpanPayStubDisplayTotalsAmount>
                        </Typography>
                    </GridPayStubTotals>
                    <GridPayStubTotals item xs={12} sm={6}>
                        <Typography sx={{fontWeight: '500', marginRight: "8px"}}>
                            <SpanPayStubDisplayTotalsLabel>YTD used / paid</SpanPayStubDisplayTotalsLabel>
                        </Typography>
                        <Typography sx={{fontWeight: '500'}}>
                            <SpanPayStubDisplayTotalsAmount> ${getNumber(vacationPay.ytdUsedPaid).toFixed(2)}</SpanPayStubDisplayTotalsAmount>
                        </Typography>
                    </GridPayStubTotals>
                    <GridPayStubTotals item xs={12} sx={{mt: 4}}>
                        <Typography variant={"h6"}>
                            <SpanPayStubDisplayTotalsLabel>Vacation accrued(remaining)</SpanPayStubDisplayTotalsLabel>
                        </Typography>
                        <Typography variant={"h6"}>
                            <SpanPayStubDisplayTotalsAmount> ${getNumber(vacationPay.vacationAccrued).toFixed(2)}</SpanPayStubDisplayTotalsAmount>
                        </Typography>
                    </GridPayStubTotals>
                </Grid>
            </Grid>
        </Grid>
    </Box>
}
export default VacationPayDisplay;