import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {DataGrid, GridToolbarQuickFilter} from "@mui/x-data-grid";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import Box from "@mui/material/Box";
import ResponsiveEditButton from "../ResponsiveEditButton";
import EditEmployee from "../Employee/EditEmployee";
import ErrorDisplay from "../../common/ErrorDisplay";
import {
    Employee,
    EmployeeWithStatus,
    GetEmployeesWithStatusQuery,
    RecommendedAction,
    RecommendedEmployeeAction,
    useGetEmployeesWithStatusLazyQuery,
} from "../../graphql/generated/graphql";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import OneTwoPayTooltip from "../../common/OneTwoPayTooltip";
import {assumedUserContext} from "../User/AssumedUserContext";


export function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
                mb: 1,
                display: 'flex',
                justifyContent: 'flex-end',
            }}
        >
            <GridToolbarQuickFilter variant={"outlined"} size={"small"}/>
        </Box>
    );
}

type OptionRow = {
    id: string;
    name: string;
    address: string;
    status: boolean;
    recommendedActions: Array<RecommendedEmployeeAction>;
}

function consolidateRecommendations(recommendedActions: Array<RecommendedAction>): RecommendedAction {

    const descriptions = recommendedActions.map(recommendedAction => recommendedAction.description);
    const consolidatedDescription = descriptions.join(" ");
    return {
        objectIdToAction: recommendedActions[0].objectIdToAction,
        actionObject: recommendedActions[0].actionObject,
        description: consolidatedDescription,
    }
}


function getFullName(employee: Employee) {
    return `${employee.firstName} ${employee.lastName}`;
}

function getAddress(employee: Employee) {
    const addressArray = [
        employee.address1,
        employee.address2,
        employee.city,
        employee.province,
        employee.postalCode
    ];
    const addressArrayWithoutNulls = addressArray.filter(item => !!item);
    return addressArrayWithoutNulls.join(', ');
}


type PropsType = {
    employeesSelectedUpdated: (employees: Array<EmployeeWithStatus>) => void;
    selectedEmployees: Array<EmployeeWithStatus> | undefined;
    multipleSelectionsAllowed: boolean;
    reloadEmployees?: boolean;
    reloaded?: () => void;
    showNeighbours: (showEm: boolean) => void;
}

function getEmployeeDataForDatagrid(employeesData: GetEmployeesWithStatusQuery) {
    return employeesData.getEmployeesWithStatus
        .filter(employeeWithStatus => !employeeWithStatus.employee.deleted)
        .map((employeeWithStatus: EmployeeWithStatus) => {
                const employee = employeeWithStatus.employee;
                let optionRow: OptionRow = {
                    id: employeeWithStatus.employee.id.toString(),
                    name: getFullName(employee),
                    address: getAddress(employee),
                    status: !employeeWithStatus.recommendedActions || employeeWithStatus.recommendedActions.length === 0,
                    recommendedActions: employeeWithStatus.recommendedActions || []
                };
                return optionRow
            }
        )
        .sort((option1: OptionRow, option2: OptionRow) => {
            let text2Upper = option2.name.toUpperCase();
            let text1Upper = option1.name.toUpperCase();
            return text1Upper < text2Upper ? -1 : text1Upper > text2Upper ? 1 : 0;
        });
}

function getColumnsForDataGrid(setEditEmployeeId: (employeeId: number) => void) {

    const warningIcon = React.forwardRef<SVGSVGElement>((props, ref) => (
        <WarningAmberRoundedIcon
            {...props}
            ref={ref}
            fontSize={"large"}
            color={"warning"}
        />
    ));

    return [
        {field: 'address', headerName: 'Address', flex: 1, cellClassName: 'cell'},
        {field: 'name', headerName: 'Name / ID', flex: 1, cellClassName: 'cell'},
        {
            field: 'status',
            headerName: 'Edit',
            flex: .5,
            cellClassName: 'cell',
            renderCell: (params: { id: any; }) => {
                return <ResponsiveEditButton
                    buttonLabel={"Edit Employee"}
                    editAction={event => {
                        event.stopPropagation();
                        setEditEmployeeId(params.id);
                    }}
                />;
            }
        },
        {
            field: 'recommendedActions',
            headerName: 'Status',
            flex: .25,
            cellClassName: 'cell',
            renderCell: (params: { id: any, row: any }) => {
                const recommendedActions = params.row.recommendedActions;
                if (!!recommendedActions && recommendedActions.length > 0) {
                    const recommendation = consolidateRecommendations(recommendedActions);
                    return <OneTwoPayTooltip
                        tipContent={recommendation.description}
                        TooltipTarget={warningIcon}
                    />
                }
                return <CheckCircleOutlineRoundedIcon fontSize={"large"} color={"success"}

                />
            }
        },
    ];
}

function getRecommendedActions(editEmployeeId: number, employees: EmployeeWithStatus[]): RecommendedEmployeeAction[] {
    const employeesWithStatus: EmployeeWithStatus | undefined = employees.find(employeeWithStatus => employeeWithStatus.employee.id === editEmployeeId);
    if (!!employeesWithStatus) {
        return employeesWithStatus.recommendedActions || [];
    }
    return [];
}

function getEmployeeWithStatusObjectsForEmployeeIds(employeeIds: Array<string>, employeesWithStatus: EmployeeWithStatus[]): Array<EmployeeWithStatus> {
    if (!employeeIds.length) {
        return [];
    }
    return employeesWithStatus
        .filter((employeeWithStatus: EmployeeWithStatus) => employeeIds.includes(employeeWithStatus.employee.id.toString()));
}


const EmployeesSelect = (props: PropsType) => {

    const {
        employeesSelectedUpdated,
        selectedEmployees,
        multipleSelectionsAllowed,
        reloadEmployees,
        reloaded,
        showNeighbours
    } = props;

    const {assumedUser} = useContext(assumedUserContext);
    const [editEmployeeId, setEditEmployeeId] = useState<number>();
    const [reloadEmployeeList, setReloadEmployeeList] = useState(false);

    const selectedEmployeeIds = useMemo(() => {
        return !!selectedEmployees && selectedEmployees.length
            ? selectedEmployees.map(selectedEmployee => selectedEmployee.employee.id.toString())
            : [];
    }, [selectedEmployees]);
    const [
        loadEmployees,
        {
            data: employeesData,
            loading: employeesLoading,
            error: employeesError
        }] = useGetEmployeesWithStatusLazyQuery();

    const filterAlreadyExistingSelectionsIfMultipleNotAllowed = useCallback((newlySelectedEmployees: Array<EmployeeWithStatus>): Array<EmployeeWithStatus> => {
        if (multipleSelectionsAllowed || newlySelectedEmployees.length < 2) {
            return newlySelectedEmployees;
        }
        return newlySelectedEmployees
            .filter(employee => !selectedEmployeeIds.includes(employee.employee.id.toString()));
    }, [multipleSelectionsAllowed, selectedEmployeeIds]);

    const triggerSelectedEmployeesUpdated = useCallback((newlySelectedEmployeeIds: Array<string>, employeesData: EmployeeWithStatus[]) => {
        const newlySelectedEmployees = getEmployeeWithStatusObjectsForEmployeeIds(newlySelectedEmployeeIds, employeesData);
        const updatedSelection = filterAlreadyExistingSelectionsIfMultipleNotAllowed(newlySelectedEmployees); // we won't need this if we can figure out how to do multiple ROEs at the same time.
        employeesSelectedUpdated(updatedSelection);
    }, [filterAlreadyExistingSelectionsIfMultipleNotAllowed, employeesSelectedUpdated]);

    const userId = assumedUser.id;
    useEffect(() => {
        if (!!userId && !employeesLoading && (!employeesData || reloadEmployees || reloadEmployeeList)) {
            loadEmployees({
                variables: {
                    userId: userId
                }
            })
                .then(data => {
                    !!reloaded && reloaded();
                    setReloadEmployeeList(false);
                    if (!!data.data?.getEmployeesWithStatus) {
                        triggerSelectedEmployeesUpdated(selectedEmployeeIds, data.data.getEmployeesWithStatus);
                    }
                });

        }
    }, [userId, reloadEmployees, loadEmployees, employeesLoading, employeesData, triggerSelectedEmployeesUpdated, selectedEmployeeIds, reloaded, reloadEmployeeList]);


    if (!!editEmployeeId && !!employeesData) {
        return <EditEmployee
            employeeId={editEmployeeId}
            close={() => {
                setEditEmployeeId(undefined);
                showNeighbours(true);
            }}
            employeeAddedEdited={() => {
                setEditEmployeeId(undefined);
                showNeighbours(true);
                setReloadEmployeeList(true);
            }}
            recommendedActions={getRecommendedActions(Number(editEmployeeId), employeesData.getEmployeesWithStatus)}
        />
    }


    if (employeesLoading || employeesError) {
        return <LoadingErrorDisplay loading={employeesLoading} apolloError={employeesError}/>
    }

    if (!employeesData || !employeesData.getEmployeesWithStatus || employeesData?.getEmployeesWithStatus.length < 1) {
        return <ErrorDisplay
            stringError={"No employees with finalized pay stubs found. Please create and finalize at least one pay stub before running any reports."}
        />
    }

    const editEmployee = (id: number) => {
        showNeighbours(false);
        setEditEmployeeId(id);
    }

    return <Box component='div' sx={{maxHeight: "400px", width: '100%', marginTop: "10px", marginBottom: 3.5}}>
        <DataGrid
            rows={getEmployeeDataForDatagrid(employeesData)}
            columns={getColumnsForDataGrid(editEmployee)}
            checkboxSelection
            rowSelectionModel={selectedEmployeeIds}
            onRowSelectionModelChange={idsSelected => triggerSelectedEmployeesUpdated(idsSelected as Array<string>, employeesData.getEmployeesWithStatus)}
            slots={{toolbar: QuickSearchToolbar}}
            pageSizeOptions={[5, 10, 25]}
            initialState={{
                pagination: {paginationModel: {pageSize: 5}},
            }}
            sx={{
                '& .MuiDataGrid-cell': {
                    fontSize: '1.25rem',
                },
                '& .MuiDataGrid-columnHeaders': {
                    fontSize: '1.25rem',
                },
                '& .MuiDataGrid-footerContainer': {
                    fontSize: '1.25rem',
                },
                '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows, & .MuiTablePagination-select, & .MuiTablePagination-actions': {
                    fontSize: '1.25rem',
                },
            }}
        />
    </Box>

}

export default EmployeesSelect;