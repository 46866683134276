import {Controller, FieldError, FieldPath, FieldValues} from "react-hook-form";
import React from "react";
import {FormControl, InputLabel, Select} from "@mui/material";
import {Control} from "react-hook-form/dist/types";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import ErrorDisplay from "./ErrorDisplay";
import FormHelperText from "@mui/material/FormHelperText";

type PropsType<T extends FieldValues> = {
    nameOfFormField: FieldPath<T>,
    label: string,
    control: Control<T>, // T is the class of the object controlled by the form.
    children: Array<ReactJSXElement>,
    requiredMessage?: string | boolean;
    error?: FieldError | undefined;
    valueChanged?: (value: string) => void;
}

const ReactHookFormSelect = <T extends FieldValues>(props: PropsType<T>) => {

    const {
        nameOfFormField,
        label,
        control,
        children,
        requiredMessage,
        error,
        valueChanged
    } = props;

    const labelId = `${nameOfFormField}-label`;
    const errorMessage = error?.message;

    return <FormControl fullWidth>

        <Controller
            render={({field: {onChange, value}}) => {
                return (<>
                        <InputLabel id={labelId}>{label}</InputLabel>
                        <Select
                            id={labelId}
                            label={label}
                            onChange={event => {
                                onChange(event);
                                if (!!valueChanged) {
                                    valueChanged(event.target.value);
                                }
                            }
                            }
                            error={!!error}
                            value={!!value ? value : ''}
                        >
                            {children}
                        </Select>
                    </>
                );
            }}
            control={control}
            name={nameOfFormField}
            rules={{required: requiredMessage}}
        />
        <FormHelperText>
            <ErrorDisplay
                stringError={errorMessage}
            />
        </FormHelperText>
    </FormControl>;
};
export default ReactHookFormSelect;