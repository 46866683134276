import {useParams} from "react-router-dom";
import {Typography} from "@mui/material";
import React, {useCallback, useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";
import useSystemNotices from "../../Utils/useSystemNotices";
import PopUpBlockerHandler from "../../common/PopUpBlockerHandler";

type ParamsType = {
    redirect: string
}

let signUpPrompted = false;

const LegacyAppSignUp = () => {
    const {redirect} = useParams<ParamsType>();
    const {isLoading, isAuthenticated, loginWithPopup} = useAuth0();
    const {sendNotice} = useSystemNotices();


    const signUp = useCallback(() => {

        signUpPrompted = true;
        loginWithPopup({
            authorizationParams: {
                screen_hint: 'signup',
            }
        })
            .catch(error => {
                console.error(error);
                sendNotice(`Error thrown by loginWithPopup on legacy Signup page. Error: ${error.message}`);
            });

    }, [loginWithPopup, sendNotice]);

    useEffect(() => {
        if (isLoading) {
            return;
        }
        if (!isAuthenticated) {
            signUp();
            return;
        }
    }, [signUp, loginWithPopup, sendNotice, isLoading, isAuthenticated]);


    useEffect(() => {
        if (isLoading || !isAuthenticated) {
            return;
        }
        if (!redirect) {
            window.location.href = `${process.env.REACT_APP_APP_URL}`;
            return;
        }
        window.location.href = `${process.env.REACT_APP_APP_URL}/${redirect}`;
    }, [redirect, isLoading, isAuthenticated]);


    if (!isAuthenticated && signUpPrompted) {
        const message = `It looks like your browser might be blocking the pop-up needed to sign in.\n 
                                Please try again by clicking the Sign up button here. If you continue having difficulty, please\n
                                                                try a different browser or contact us for assistance.`;
        return <PopUpBlockerHandler
            triggerAction={signUp}
            actionLabel={"Sign up"}
            message={message}
        />

    }

    return <Typography
        variant="h3"
    >

        Signing in
    </Typography>
}
export default LegacyAppSignUp;