import {IconButton, SxProps, Theme} from "@mui/material";
import OneTwoPayTooltip from "./OneTwoPayTooltip";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";


type PropsType = {
    toolTip: string
    deleteAction: () => void;
    sx?: SxProps<Theme> | undefined;
    iconSx?: SxProps<Theme> | undefined;
}

const OneTwoPayDeleteIconWithTooltip = (props: PropsType) => {
    const {toolTip, deleteAction, sx, iconSx} = props;


    const otpIconButton = React.forwardRef<HTMLButtonElement>((props, ref) => (
        <IconButton
            {...props}
            ref={ref}
            aria-label="delete"
            onClick={deleteAction}
            sx={sx}
            color={'error'}
        >
            <DeleteIcon sx={iconSx}/>
        </IconButton>
    ));

    return <OneTwoPayTooltip
        tipContent={toolTip}
        TooltipTarget={otpIconButton}
    >
    </OneTwoPayTooltip>
}
export default OneTwoPayDeleteIconWithTooltip;