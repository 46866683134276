import {Button, IconButton, SxProps, Theme, useMediaQuery} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";

type PropsType = {
    editAction: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    buttonLabel: string;
    editIcon?: React.ReactNode;
    sx?: SxProps<Theme> | undefined;
}
const ResponsiveEditButton = (props: PropsType) => {
    const isSmallScreen = useMediaQuery('(max-width:800px)');
    const {editAction, buttonLabel, editIcon, sx} = props;
    let editIconToUse = !!editIcon ? editIcon : <EditIcon/>;
    return isSmallScreen ? <IconButton
            aria-label="edit"
            size="small"
            onClick={editAction}
        >
            {editIconToUse}
        </IconButton>
        :
        <Button
            variant="outlined"
            size="small"
            onClick={editAction}
            sx={sx}
        >
            {buttonLabel}
        </Button>;
}

export default ResponsiveEditButton;