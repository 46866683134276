import React, {useContext, useState} from "react";
import {DeductionType, useSaveDeductionTypeMutation,} from "../../graphql/generated/graphql";
import {Button, Grid, MenuItem, TextField, Typography} from "@mui/material";
import {useForm} from "react-hook-form";
import ReactHookFormSelect from "../../common/ReactHookFormSelect";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import useAnonCookie from "../../security/useAnonCookie";
import GET_DEDUCTION_TYPES from "../../graphql/queries/getDeductionTypes";
import {assumedUserContext} from "../User/AssumedUserContext";

type PropsType = {
    currentPayStubId: number | undefined;
    deductionType: DeductionType;
    setCustomDeductionType: (deductionType: DeductionType) => void;
    close: () => void;
    deductionTypes: Array<DeductionType>
}

function getTaxTreatmentMap(deductionTypes: Array<DeductionType>) {
    return new Map(
        deductionTypes
            .map(deductionType => [deductionType.taxTreatment.code, deductionType.taxTreatment])
    );
}

type DeductionTypeForForm = {
    id: number
    label: string
    taxTreatmentCode: string
}


function getDeductionTypeForForm(deductionType: DeductionType): DeductionTypeForForm {
    return {
        id: deductionType.id,
        label: deductionType.label,
        taxTreatmentCode: deductionType.taxTreatment.code
    }
}

const CustomDeductionTypeEdit = (props: PropsType) => {
    const {
        deductionType,
        setCustomDeductionType,
        currentPayStubId,
        close,
        deductionTypes
    } = props;

    const [error, setError] = useState<string>();
    const {getAnonUserId} = useAnonCookie();
    const {assumedUser} = useContext(assumedUserContext);

    const [
        saveCustomDeductionType,
        {
            loading: saveCustomDeductionTypeLoading,
            error: saveCustomDeductionTypeError
        }] = useSaveDeductionTypeMutation();

    const {
        register,
        control,
        formState: {errors},
        handleSubmit,
        watch
    } = useForm<DeductionTypeForForm>({
        defaultValues: getDeductionTypeForForm(deductionType)
    });

    const saveNewCustomDeductionType = (data: DeductionTypeForForm) => {
        // setCustomDeductionType(undefined);
        saveCustomDeductionType({
            variables: {
                input: {
                    id: data.id,
                    label: data.label,
                    taxTreatmentCode: data.taxTreatmentCode,
                    userId: assumedUser.id
                },
                anonUserId: getAnonUserId(),
                payStubId: currentPayStubId
            },
            refetchQueries: [
                {
                    query: GET_DEDUCTION_TYPES,
                    variables: {
                        anonUserId: getAnonUserId(),
                        userId: assumedUser.id
                    },
                }
            ]
        })
            .then(savedCustomDeductionType => {
                let responseData = savedCustomDeductionType.data;
                if (!responseData) {
                    setError("Error saving deduction type. Please try again or contact support.")
                } else {
                    setCustomDeductionType(responseData.saveDeductionType);
                    close();
                }
            });
    }

    const taxTreatmentMap = getTaxTreatmentMap(deductionTypes);
    const distinctTaxTreatments = Array.from(taxTreatmentMap.values());

    const taxTreatmentCode = watch('taxTreatmentCode');
    const selectedTaxTreatment = taxTreatmentMap.get(taxTreatmentCode);

    return <form noValidate onSubmit={handleSubmit(saveNewCustomDeductionType)}>
        <Grid container spacing={2} sx={{p: 2}}>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={"Label"}
                    {...register("label", {required: "Label is required"})}
                    error={!!errors.label}
                    helperText={(errors.label?.message || '') as string}
                />
            </Grid>
            <Grid item xs={12}>
                {!!distinctTaxTreatments &&
                    <ReactHookFormSelect
                        control={control}
                        label={"Tax treatment"}
                        nameOfFormField={`taxTreatmentCode`}
                        requiredMessage={"Tax treatment is required"}
                    >
                        {distinctTaxTreatments.map(taxTreatment => (
                            <MenuItem key={taxTreatment.code} value={taxTreatment.code}>
                                {taxTreatment.label}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                }
            </Grid>
            <Grid item xs={12} sx={{ml: 2, mb: 2}}>
                <Typography
                    variant="body2"
                >
                    {selectedTaxTreatment?.description}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Button
                    variant={"contained"}
                    fullWidth
                    color={"error"}
                    onClick={close}
                >
                    {"Cancel"}
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Button
                    variant={"contained"}
                    fullWidth
                    type={"submit"}
                >
                    Save deduction type
                </Button>
            </Grid>
            <Grid item xs={12}>
                <LoadingErrorDisplay
                    loading={saveCustomDeductionTypeLoading}
                    apolloError={saveCustomDeductionTypeError}
                    stringError={error}
                />
            </Grid>
        </Grid>
    </form>

}
export default CustomDeductionTypeEdit;