import React, {useCallback, useContext, useEffect} from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";
import useSystemNotices from "../Utils/useSystemNotices";
import {userContext} from "../components/User/UserContext";
import {useNavigate} from "react-router-dom";
import {useGetEmployeesLazyQuery, User} from "../graphql/generated/graphql";
import useAnonCookie from "../security/useAnonCookie";
import {MY_ACCOUNT} from "../common/AppLinks";
import LoadingErrorDisplay from "../common/LoadingErrorDisplay";
import PopUpBlockerHandler from "../common/PopUpBlockerHandler";

let signUpPrompted = false;

function hasUserId(user: User | undefined) {
    return !!user && !!user.id && user.id > 0
}

const Signup = () => {

    const {isLoading, isAuthenticated, loginWithPopup} = useAuth0();
    const {user} = useContext(userContext);
    const {sendNotice} = useSystemNotices();
    const navigate = useNavigate();
    const {getAnonUserId} = useAnonCookie();

    const [
        getEmployees,
        {
            data: employeesData,
            loading: employeesLoading,
            error: employeesError
        }
    ] = useGetEmployeesLazyQuery();

    const signUp = useCallback(() => {
        signUpPrompted = true;
        loginWithPopup({
            authorizationParams: {
                screen_hint: 'signup',
            }
        })
            .catch(error => {
                console.error(error);
                sendNotice(`Error thrown by loginWithPopup on Signup page. Error: ${error.message}`);
            });
    }, [loginWithPopup, sendNotice]);

    useEffect(() => {
        if (isLoading) {
            return;
        } else if (!isAuthenticated && !signUpPrompted) {
            signUp();
            return;
        }
    }, [isAuthenticated, user, signUp, sendNotice, navigate, isLoading]);

    useEffect(() => {
        if (hasUserId(user) && !employeesData && !employeesLoading && !employeesError) {
            getEmployees({
                variables: {
                    anonUserId: getAnonUserId(),
                    userId: user.id
                }
            })
                .catch(error => {
                    sendNotice(`error getting employees on sign up page: ${error.message}`);
                });
        }
    }, [user, getAnonUserId, getEmployees, sendNotice, employeesData, employeesError, employeesLoading]);

    if (!isAuthenticated && signUpPrompted) {
        const message = `It looks like your browser might be blocking the pop-up needed to sign in.\n 
                                Please try again by clicking the Sign up button here. If you continue having difficulty, please\n
                                                                try a different browser or contact us for assistance.`;
        return <PopUpBlockerHandler
            triggerAction={signUp}
            actionLabel={"Sign up"}
            message={message}
        />

    }

    if (employeesLoading || !!employeesError) {
        return <LoadingErrorDisplay loading={employeesLoading} apolloError={employeesError}/>
    }

    const employees = employeesData?.getEmployees?.employees;
    const hasEmployees = !!employees && employees.length > 0;
    if (hasUserId(user)) {
        return <Grid
            container
            justifyContent='center'
            alignItems='center'
            style={{minHeight: '30vh'}}
        >
            <Box
                sx={{
                    maxWidth: "500px",
                    width: {
                        xs: "100%",

                    },

                }}

            >
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            fontSize: "2rem !important",
                            textAlign: 'center'
                        }}
                    >
                        You are signed in to OneTwoPay.
                    </Typography>
                </Grid>
                <Grid container flexDirection={"row"}>
                    <Grid container direction="column" alignItems="center" justifyContent="center" item xs={12} sm={6}>
                        {
                            hasEmployees &&
                            <Button
                                sx={{
                                    mt: 3, fontSize: {
                                        xs: '1rem !important',
                                        sm: '1.5rem !important',
                                    }
                                }}
                                variant="contained"
                                size={"large"}
                                onClick={() => navigate("/employees")}
                            >
                                View employees
                            </Button>
                        }
                        {
                            !hasEmployees &&
                            <Button
                                sx={{
                                    mt: 3, fontSize: {
                                        xs: '1rem !important',
                                        sm: '1.5rem !important',
                                    }
                                }}
                                variant="contained"
                                size={"large"}
                                onClick={() => navigate("/pay-stub")}
                            >
                                Create a pay stub
                            </Button>
                        }
                    </Grid>
                    <Grid container direction="column" alignItems="center" justifyContent="center" item xs={12} sm={6}>
                        <Button
                            sx={{
                                mt: 3, fontSize: {
                                    xs: '1rem !important',
                                    sm: '1.5rem !important',
                                }
                            }}
                            variant="contained"
                            size={"large"}
                            onClick={() => window.location.href = MY_ACCOUNT}
                        >
                            My account
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    }
    return <Typography>
        Signing up for OneTwoPay
    </Typography>
}
export default Signup;