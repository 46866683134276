import {VacationPay, VacationPayCode} from "../../../graphql/generated/graphql";
import {Button, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Typography} from "@mui/material";
import React from "react";
import {useForm} from "react-hook-form";
import ReactHookFormSelect from "../../../common/ReactHookFormSelect";
import ReactFormDatePicker from "../../../common/ReactHookFormDatePicker";
import OneTwoPayNumericTextField from "../../../common/OneTwoPayNumericTextField";
import CloseDialogButton from "../../../common/CloseDialogButton";

type PropsType = {
    vacationPay: VacationPay | undefined | null;
    updateVacationPay: (vacationPay: VacationPay) => void;
    close: () => void;
}

function getEmptyVacationPay(): VacationPay {
    return {
        amount: "",
        endDate: "",
        startDate: "",
        vacationPayCode: VacationPayCode.IncludedWithEachPay
    }
}

const RoeVacationPay = (props: PropsType) => {
    const {vacationPay, updateVacationPay, close} = props;
    const {
        register,
        getValues,
        formState: {
            errors
        },
        watch,
        control
    } = useForm<VacationPay>({
        defaultValues: vacationPay || getEmptyVacationPay()
    });
    const saveVacationPay = () => {
        updateVacationPay(getValues());
        close();
    }

    const codes = Object.values(VacationPayCode);
    const amountValue = watch("amount");
    return <Dialog
        open={true}
        fullWidth={true}
    >
        <DialogTitle>
            <CloseDialogButton
                close={close}
            />
        </DialogTitle>
        <DialogContent>
            <Typography variant={"h6"}> Vacation pay information (optional)</Typography>
            <form noValidate onSubmit={e => {
                saveVacationPay();
                e.preventDefault();
                e.stopPropagation();
            }}>
                <Grid container spacing={2} sx={{mt: 1}}>
                    <Grid item xs={6}>
                        <ReactHookFormSelect
                            control={control}
                            label={"Vacation pay code"}
                            nameOfFormField={"vacationPayCode"}
                            requiredMessage={"Vacation pay code is required"}
                        >
                            {codes.map(code => {
                                return <MenuItem key={code} value={code}>
                                    {code}
                                </MenuItem>
                            })}
                        </ReactHookFormSelect>
                    </Grid>
                    <Grid item xs={6}>
                        <OneTwoPayNumericTextField
                            label={"Amount"}
                            amount={amountValue || ""}
                            {...register("amount")}
                            error={!!errors.amount}
                            errorText={errors.amount?.message}
                            variant={"outlined"}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ReactFormDatePicker<VacationPay>
                            fieldName={"startDate"}
                            label={"Start date"}
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ReactFormDatePicker<VacationPay>
                            fieldName={"endDate"}
                            label={"End date"}
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant={"contained"}
                            style={{fontSize: '1.2rem'}}
                            color={"error"}
                            onClick={() => close()}
                            size={"large"}
                        >
                            {"Cancel"}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant={"contained"}
                            type={"submit"}
                            style={{fontSize: '1.2rem'}}
                            size={"large"}
                        >
                            Save vacation pay
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </DialogContent>
    </Dialog>
}
export default RoeVacationPay;