import * as React from "react";
import {createContext, ReactNode, useContext} from "react";
import {User} from "../../graphql/generated/graphql";
import {DEFAULT_USER_PROFILE, userContext} from "./UserContext";


type PropsType = {
    children: ReactNode;
}

type ContextType<T> = {
    assumedUser: T;
    setAssumedUser: React.Dispatch<React.SetStateAction<T>>;
};


const defaultContext: ContextType<User> = {
    assumedUser: DEFAULT_USER_PROFILE,
    setAssumedUser: () => {
    },
};

export const assumedUserContext = createContext<ContextType<typeof DEFAULT_USER_PROFILE>>(defaultContext);

const AssumedUserContext = (props: PropsType) => {
    const {children} = props;
    const {user: signedInUser} = useContext(userContext);
    const [assumedUser, setAssumedUser] = React.useState<User>(signedInUser);


    return (
        <assumedUserContext.Provider
            value={{
                assumedUser: assumedUser,
                setAssumedUser: setAssumedUser,
            }}
        >
            {children}
        </assumedUserContext.Provider>
    );

}
export default AssumedUserContext;