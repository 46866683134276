import {makeStyles} from "@mui/styles";
import {IconButton, Tooltip} from "@mui/material";
import {HelpOutline} from "@mui/icons-material";
import React, {ForwardRefExoticComponent, RefAttributes} from "react";


const useStyles = makeStyles(() => ({
    customTooltip: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '1.15rem',
        border: '2px solid #2F61F6',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)'
    },
    arrow: {
        color: 'transparent',
    },
}));

type PropsType = {
    tipContent: string;
    onClick?: () => void;
    TooltipTarget?: ForwardRefExoticComponent<RefAttributes<any>>;
    spaceLeft?: string;
}

const OneTwoPayTooltip = (props: PropsType) => {

    const {tipContent, TooltipTarget, onClick, spaceLeft} = props;

    const classes = useStyles();

    return <Tooltip
        sx={{pl: spaceLeft || 0}}
        title={<span> {tipContent} </span>}
        placement="right-end"
        classes={{tooltip: classes.customTooltip, arrow: classes.arrow}}
        arrow
    >
        {
            !!TooltipTarget
                ? <TooltipTarget/>
                : <IconButton
                    onClick={onClick}
                >
                    <HelpOutline color="primary"/>
                </IconButton>
        }
    </Tooltip>


}

export default OneTwoPayTooltip;