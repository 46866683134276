import React, {useEffect, useState} from "react";
import {
    IncomeItem,
    IncomeItemInput,
    IncomeType,
    SaveIncomeItemMutation,
    useSaveIncomeItemMutation
} from "../../graphql/generated/graphql";
import {useForm} from "react-hook-form";
import {Button, Grid, Typography} from "@mui/material";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import useAnonCookie from "../../security/useAnonCookie";
import usePayStubIdManager from "./usePayStubIdManager";
import IncomeTypeSelectEdit from "./IncomeTypeSelectEdit";
import {getNumber} from "../../Utils/stringMath";
import {FetchResult} from "@apollo/client";
import {getRateLabel, getUnitsLabel} from "./incomeTypeFunctions";
import useSystemNotices from "../../Utils/useSystemNotices";
import OneTwoPayNumericTextField from "../../common/OneTwoPayNumericTextField";
import ReactFormSwitch from "../../common/ReactFormSwitch";
import useCalculateVacationSummary from "./useCalculateVacationSummary";

export type ValidationResult = {
    valid: boolean;
    message?: string;
}

type PropsType = {
    incomeItem: IncomeItem;
    isRateBased: boolean;
    incomeTypes: Array<IncomeType>;
    close: () => void;
    deleteItem: (is: string) => void;
    validateIncomeType: (incomeTypeId: string, incomeItemId: string) => ValidationResult;
    incomeItemUpdated: () => void;
    setIncomeType: (incomeType: IncomeType) => void;
    incomeType: IncomeType
    employeeAccruingVacation: boolean;
}


function calculateRateUnitsCurrentAmount(values: IncomeItem) {
    let rate = getNumber(values.rate);
    let units = getNumber(values.units);
    if (!!rate && !!units) {
        return rate * units;
    }
    return undefined;
}

function calculateYtdAmount(values: IncomeItem, isRateBased: boolean) {
    let currentAmount = isRateBased ? calculateRateUnitsCurrentAmount(values) : getNumber(values.amount);
    if (!currentAmount) {
        currentAmount = 0;
    }
    let priorPeriodAmount = getNumber(values.priorPeriodAmount);
    return currentAmount + priorPeriodAmount;
}

function getIncomeItemInput(data: IncomeItem, incomeTypeId: string, payStubId: number, currentPeriodId: string, priorPeriodId: string, isRateBased: boolean): IncomeItemInput {
    const rate = isRateBased ? data.rate : "0";
    const units = isRateBased ? data.units : "0";
    const amount = isRateBased ? "0" : data.amount;
    return {
        currentPeriodItemId: currentPeriodId,
        priorPeriodItemId: priorPeriodId,
        incomeTypeId: incomeTypeId,
        rate: rate,
        units: units,
        amount: amount,
        priorPeriodAmount: data.priorPeriodAmount,
        payStubId: payStubId,
        includeInVacationAccrual: data.includeInVacationAccrual
    }
}

function incomeTypesContainsSelectedIncomeType(incomeTypes: Array<IncomeType>, incomeTypeToFind: IncomeType): boolean {
    if (!incomeTypes || incomeTypes.length < 1) {
        return false;
    }
    return incomeTypes.some(incomeType => incomeType.id === incomeTypeToFind.id);
}

const IncomeItemEdit = (props: PropsType) => {
    const {
        incomeItem,
        isRateBased,
        incomeTypes,
        close,
        validateIncomeType,
        incomeItemUpdated,
        setIncomeType,
        incomeType,
        employeeAccruingVacation
    } = props;

    const [ytdAmount, setYtdAmount] = useState(calculateYtdAmount(incomeItem, isRateBased));
    const [error, setError] = useState<string>();
    const {getAnonUserId} = useAnonCookie();
    const anonUserId = getAnonUserId();
    const {recalculateVacationSummary} = useCalculateVacationSummary();


    const {
        getPayStubId,
        createNewPayStub,
        createNewPayStubLoading,
        createNewPayStubError
    } = usePayStubIdManager();
    let payStubId = getPayStubId();
    const {sendNotice} = useSystemNotices();

    const {
        handleSubmit,
        register,
        getValues,
        formState: {errors},
        watch,
        setValue,
        control
    } = useForm<IncomeItem>(
        {
            defaultValues: incomeItem
        }
    );

    const updateIncomeType = (incomeType: IncomeType) => {
        setError(undefined);
        setIncomeType(incomeType);
    }

    const [
        saveIncomeItem,
        {
            error: saveIncomeItemError,
            loading: saveIncomeItemLoading
        }
    ] = useSaveIncomeItemMutation();


    const updateIncomeItem = async (data: IncomeItem) => {
        const result = validateIncomeType(incomeType.id, data.id);
        if (!result.valid && !!result.message) {
            setError(result.message);
            return;
        }


        if (!payStubId) {
            payStubId = await createNewPayStub();
        }
        if (!payStubId) {
            setError("Unable to initiate new pay stub. Please try again or contact support.");
            return;
        }
        const incomeItemInput: IncomeItemInput = getIncomeItemInput(data, incomeType.id, payStubId, incomeItem.currentPeriodItemId, incomeItem.priorPeriodItemId, isRateBased);

        saveIncomeItem({
            variables: {
                input: incomeItemInput,
                anonUserId: anonUserId
            },
        })
            .then((result: FetchResult<SaveIncomeItemMutation>) => {
                let saveResult = result.data?.saveIncomeItem;
                if (saveResult && saveResult.successful) {
                    incomeItemUpdated();
                    if (employeeAccruingVacation) {
                        recalculateVacationSummary();
                    }
                    close();
                } else if (saveResult?.message) {
                    setError(saveResult.message);
                } else {
                    sendNotice(`Save income item failed without message. Pay stub id: ${payStubId}`);
                    setError("Error saving income item. Please try again or contact support.")
                }
            })
            .catch(error => {
                sendNotice(`Save income item failed without message. Pay stub id: ${payStubId}. Error: ${error.message}`);
                setError("Error saving income item. Please try again or contact support.");
            });
    }

// careful with this. It changes and triggers a re-render anytime any of the values are set, even if they don't change.
    const watchedValues = watch(['amount', 'rate', 'units', 'priorPeriodAmount']);
    useEffect(() => {
        let values = getValues();
        setYtdAmount(calculateYtdAmount(values, isRateBased));
        const rateUnitsAmount = calculateRateUnitsCurrentAmount(values);
        if (isRateBased && !!rateUnitsAmount && rateUnitsAmount !== getNumber(values.amount)) {
            setValue("amount", rateUnitsAmount.toFixed(2));
        }
        if (!!rateUnitsAmount && !isRateBased) {
            setValue("rate", "0");
            setValue("units", "0");
        }
    }, [setYtdAmount, setValue, isRateBased, watchedValues, getValues]);


    const amountFields: JSX.Element = isRateBased ? (
            <Grid container direction="row" alignItems="center" columnSpacing={3} rowSpacing={2} sx={{mb: 2}}>
                <Grid item xs={8} sm={4}>
                    <OneTwoPayNumericTextField
                        label={getUnitsLabel(incomeType.id, incomeTypes)}
                        amount={getValues("units") || ""}
                        {...register("units")}
                        error={!!errors.units}
                        errorText={errors.units?.message}
                        variant={"outlined"}
                        prefix={false}
                    />
                </Grid>
                <Grid item xs={8} sm={4}>
                    <OneTwoPayNumericTextField
                        label={getRateLabel(incomeType.id, incomeTypes)}
                        amount={getValues("rate") || ""}
                        {...register("rate")}
                        error={!!errors.rate}
                        errorText={errors.rate?.message}
                        variant={"outlined"}

                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography component="h6" color="text.secondary">Total Period Amount</Typography>
                    <Typography variant="h6" color="textPrimary">
                        ${(getNumber(getValues().rate) * getNumber(getValues().units)).toFixed(2)}
                    </Typography>
                </Grid>
            </Grid>
        )
        :
        (<Grid item xs={8} sm={4} sx={{mb: 2, pr: {xs: 1, sm: 2}}}>
            <OneTwoPayNumericTextField
                label={"Amount"}
                amount={getValues("amount")}
                {...register("amount")}
                error={!!errors.amount}
                errorText={errors.amount?.message}
                variant={"outlined"}

            />
        </Grid>)

    const incomeTypeAvailable = incomeTypesContainsSelectedIncomeType(incomeTypes, incomeType);

    return <>

        <Grid item xs={12} sm={8} sx={{px: 2}}>
            {
                incomeTypeAvailable &&
                <IncomeTypeSelectEdit
                    incomeType={incomeType}
                    setIncomeType={updateIncomeType}
                    incomeTypes={incomeTypes}
                    currentPayStubId={getPayStubId()}
                    close={close}
                />
            }
        </Grid>

        <form noValidate onSubmit={handleSubmit(updateIncomeItem)}>

            <Grid item xs={12} sx={{mt: 3, px: 2}}>
                {amountFields}
            </Grid>
            <Grid container direction="row" justifyContent={`${isRateBased ? "flex-end" : "flex-start"}`}
                  alignItems='center' columnSpacing={3} sx={{mb: 5, px: 2}}>
                <Grid item xs={8} sm={4}>
                    <OneTwoPayNumericTextField
                        label={"Prior Periods Amounts"}
                        amount={getValues("priorPeriodAmount")}
                        {...register("priorPeriodAmount")}
                        error={!!errors.priorPeriodAmount}
                        errorText={errors.priorPeriodAmount?.message}
                        variant={"outlined"}

                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography component="h6" color="text.secondary">YTD Amount</Typography>
                    <Typography variant="h6" color="textPrimary">
                        ${ytdAmount.toFixed(2)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{mb: 3}}>
                {
                    employeeAccruingVacation &&
                    <ReactFormSwitch
                        fieldName={"includeInVacationAccrual"}
                        label={"Include in vacation accrual"}
                        control={control}
                    />
                }
            </Grid>
            <Grid
                container
                direction={{xs: "column-reverse", sm: "row"}}
                alignItems={{xs: "stretch", sm: "center"}}
                justifyContent="space-between"
                sx={{px: 2}}
                rowSpacing={1.2}
            >
                <Grid item xs={8} sm={3.8}>
                    <Button
                        variant={"contained"}
                        style={{fontSize: '1.2rem'}}
                        fullWidth
                        color={"error"}
                        onClick={close}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={8} sm={3.8}>
                    <Button
                        variant={"contained"}
                        fullWidth
                        type={"submit"}
                        style={{fontSize: '1.2rem'}}
                    >
                        {incomeType?.isBenefit ? "Save benefit item" : "Save income item"}
                    </Button>
                </Grid>
            </Grid>
        </form>
        {(saveIncomeItemLoading ||
                !!saveIncomeItemError ||
                createNewPayStubLoading ||
                !!createNewPayStubError ||
                !!error
            ) &&
            <LoadingErrorDisplay
                loading={saveIncomeItemLoading || createNewPayStubLoading}
                apolloError={saveIncomeItemError || createNewPayStubError}
                stringError={error}
            />
        }
    </>
}
export default IncomeItemEdit;