import React, {ReactNode, useEffect, useState} from "react";
import {useUpdateUserAuthIdsMutation} from "../../graphql/generated/graphql";
import useSystemNotices from "../../Utils/useSystemNotices";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import {useAuth0} from "@auth0/auth0-react";
import EmailVerificationDialog from "./EmailVerificationDialog";

type PropsType = {
    children: ReactNode
}

const UserAuthIdManager = (props: PropsType) => {
    const {children} = props;
    const [updateDone, setUpdateDone] = useState<boolean>(false);
    const {isAuthenticated, isLoading: authLoading} = useAuth0();
    const [openUserNotVerifiedDialog, setOpenUserNotVerifiedDialog] = useState(false);

    const {sendNotice} = useSystemNotices();

    const [
        updateUserAuthIds,
        {
            data,
            loading: updateUserAuthIdsLoading,
            error
        }
    ] = useUpdateUserAuthIdsMutation();


    useEffect(() => {
        if (authLoading) {
            return;
        }
        if (!isAuthenticated) {
            setUpdateDone(true);
            return;
        }
        if (!data && !updateUserAuthIdsLoading && !error) {
            updateUserAuthIds()
                .then(result => {
                    if (!result.data?.updateUserAuthIds.successful) {
                        let message = result.data?.updateUserAuthIds.message;
                        sendNotice(`Error updating authIds. Message: ${message}`);
                        setOpenUserNotVerifiedDialog(true);
                    }
                    setUpdateDone(true);
                })
                .catch(error => {
                    console.error(error.message);
                    sendNotice(`Error updating authIds. Message: ${error.message}`);
                    setUpdateDone(true);
                });
        }
    }, [authLoading, isAuthenticated, data, updateUserAuthIdsLoading, error, sendNotice, updateUserAuthIds]);


    if (!updateDone) {
        return <LoadingErrorDisplay
            loading={true}
        />
    }

    if (openUserNotVerifiedDialog) {
        return <EmailVerificationDialog/>
    }
    return <>
        {children}
    </>;
}
export default UserAuthIdManager;