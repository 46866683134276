import React, {useContext, useEffect, useState} from "react";
import {Employer, useDeleteLogoMutation} from "../../graphql/generated/graphql";
import GET_EMPLOYERS from "../../graphql/queries/getEmployers";
import useAnonCookie from "../../security/useAnonCookie";
import {assumedUserContext} from "../User/AssumedUserContext";
import usePayStubIdManager from "../PayStub/usePayStubIdManager";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import YesOrNoDialog from "../../common/YesOrNoDialog";
import useSystemNotices from "../../Utils/useSystemNotices";

type PropsType = {
    logoDeleted: (employerId: number) => void;
    deleteLogoNow: boolean;
    employer: Employer;
    cancel: () => void;
}

const DeleteLogo = (props: PropsType) => {
    const {logoDeleted, employer, deleteLogoNow, cancel} = props;
    const {getAnonUserId} = useAnonCookie();
    const {assumedUser} = useContext(assumedUserContext);
    const {getPayStubId} = usePayStubIdManager();
    const {sendNotice} = useSystemNotices();
    const [deleteError, setDeleteError] = useState<string>();
    const [applyToExistingPayStubs, setApplyToExistingPayStubs] = useState<boolean>();

    const [
        deleteLogoOnEmployer,
        {
            loading: deleteLogoLoading,
            error: deleteLogoError
        }
    ] = useDeleteLogoMutation({
        refetchQueries: [
            {
                query: GET_EMPLOYERS,
                variables: {
                    userId: assumedUser.id,
                    anonUserId: getAnonUserId(),
                },
            }
        ]
    });

    useEffect(() => {
        if (applyToExistingPayStubs === undefined) {
            return;
        }
        if (!employer.id) {
            sendNotice("Cannot delete logo. Employer ID missing");
            setDeleteError("Error deleting logo. Employer missing. Please try again or contact support.");
            return
        }
        if (deleteLogoNow && !deleteLogoLoading && !deleteLogoError) {
            deleteLogoOnEmployer({
                variables: {
                    employerId: employer.id,
                    payStubId: getPayStubId(),
                    applyToExistingPayStubs: applyToExistingPayStubs,
                    anonUserId: getAnonUserId()
                }
            })
                .then(result => {
                    if (result.data && result.data.deleteLogo.successful) {
                        logoDeleted(result.data.deleteLogo.employerId);
                    } else {
                        sendNotice(`Error deleting logo on employer ID: ${employer.id}`);
                        setDeleteError("Error deleting logo. Please try again or contact support.");
                    }
                })
                .catch(error => {
                    sendNotice(`Error deleting logo on employer ID: ${employer.id}. Error: ${error.message}`);
                    setDeleteError("Error deleting logo. Please try again or contact support.");
                });
        }

    }, [deleteLogoError, deleteLogoLoading, deleteLogoNow, deleteLogoOnEmployer, employer.id, getAnonUserId, getPayStubId, logoDeleted, sendNotice, applyToExistingPayStubs]);

    if (applyToExistingPayStubs === undefined) {
        return <YesOrNoDialog
            question={"Would you like to create a new version of this employer? If not, the logo will be deleted for some or all of the previous pay stubs. Note that creating a new version does not affect reports."}
            handleBooleanAnswer={setApplyToExistingPayStubs}
            yesLabel={"No. Delete this logo completely."}
            noLabel={"Yes. Create a new version."}
            cancel={cancel}
        />
    }

    return <>
        {
            (!!deleteLogoError || !!deleteError || deleteLogoLoading) &&
            <LoadingErrorDisplay
                stringError={deleteError}
                apolloError={deleteLogoError}
                loading={deleteLogoLoading}
            />
        }
    </>
}
export default DeleteLogo;