import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";
import {AccessCheckResult, useTransferAnonUserDataMutation} from "../../graphql/generated/graphql";
import useAnonCookie from "../../security/useAnonCookie";
import {
    userNotSignedInAndNoAccess,
    userSignedInWithoutAccessAndDifferentAnonUser,
    userSignedInWithoutAccessButSameAnonUser
} from "../../Views/PayStubWithAccessCheck";
import useSystemNotices from "../../Utils/useSystemNotices";
import ErrorDisplay from "../../common/ErrorDisplay";
import CloseDialogButton from "../../common/CloseDialogButton";

type PropsType = {
    payStubAccessData: AccessCheckResult;
    payStubId: number;
    refreshAccessData: () => void;
}

type ActionButton = {
    label: string;
    action: () => void;
}

function getOptionToTransferDataMessage(payStubAccessData: AccessCheckResult) {
    const alternateActionOption = payStubAccessData.userSignedInWithoutAccessButHasEmployees ? "view employees in your account?" : "start again with a new pay stub?";
    return `It appears you created this pay stub before signing into OneTwoPay. Would you like to have this pay stub along with your other session data transferred to your OneTwoPay account? Or skip this and ${alternateActionOption} `;
}

function getSignInOrSessionTimeoutMessage(payStubAccessData: AccessCheckResult) {
    if (payStubAccessData.payStubHasUser) {
        return `Please sign in to access this pay stub`;
    }
    return `Access to this pay stub denied. Your session may have timed out. Note that pay stubs created without an account are only available temporarily. 
    We recommend creating an account to save your pay stubs. You do not need a credit card to create an account.`;
}

function getNoAccessMessage(payStubAccessData: AccessCheckResult) {
    if (payStubAccessData.payStubHasUser) {
        return `Access to this pay stub denied. Please check to see if you created this pay stub under a different account by signing out and signing in under that account. Alternatively, you can view your employees or create a new pay stub.`;
    }
    return getNoPayStubFoundMessage();
}

function getNoPayStubFoundMessage() {
    return `Access to this pay stub denied. Note that pay stubs created when not signed in are saved temporarily and your session timed out. Sorry, the pay stub is no longer available.`;
}

const PayStubAccessDialog = (props: PropsType) => {

    const {payStubAccessData, payStubId, refreshAccessData} = props;

    const {loginWithPopup} = useAuth0();
    const navigate = useNavigate();
    const [error, setError] = useState<string>();
    const [openDialog, setOpenDialog] = useState(false);
    const {getAnonUserId} = useAnonCookie();
    const {sendNotice} = useSystemNotices();

    const anonUserId = getAnonUserId();

    const [
        transferAnonUserData,
        {
            loading: transferLoading,
            error: transferError
        }
    ] = useTransferAnonUserDataMutation();


    let createPayStubAction = useCallback(() => {
        navigate("/pay-stub")
    }, [navigate]);

    const createNewPayStub: ActionButton = useMemo(() => ({
        action: createPayStubAction,
        label: "Create new pay stub"
    }), [createPayStubAction]);

    let viewEmployeesAction = useCallback(() => {
        navigate("/employees")
    }, [navigate]);

    const viewEmployees: ActionButton = useMemo(() => ({
        action: viewEmployeesAction,
        label: "View employees"
    }), [viewEmployeesAction]);


    let loginAction = useCallback(() => {
        loginWithPopup()
            .catch(error => {
                sendNotice(`Error trying to login on PayStubAccessDialog. Auth0 message: ${error.message}`)
            })
    }, [sendNotice, loginWithPopup]);

    const login: ActionButton = useMemo(() => ({
        action: loginAction,
        label: "Sign in"
    }), [loginAction]);

    const transferDataCallback = useCallback(() => {
        transferAnonUserData(
            {
                variables: {
                    anonUserId: anonUserId
                }
            })
            .then(result => {
                if (result.data?.transferAnonUserData.successful) {
                    console.log("transfer successful. Calling refreshAccessData!");
                    refreshAccessData();
                } else {
                    console.error("Error transferring anonymous user data: ", result.data?.transferAnonUserData.message);
                    sendNotice(`Error transferring anonymous user data for pay stub id: ${payStubId}`);
                    setError("Sorry, we were unable to transfer your data. Support has been notified.");
                }
            })
            .catch(error => {
                console.error("Error transferring anonymous user data: ", error.message);
                sendNotice(`Error transferring anonymous user data for pay stub id: ${payStubId}`);
                setError("Sorry, we were unable to transfer your data. Support has been notified.");
            });
    }, [transferAnonUserData, payStubId, anonUserId, sendNotice, refreshAccessData]);

    const requestTransfer = useMemo(() => ({
        action: transferDataCallback,
        label: "Transfer data"
    }), [transferDataCallback]);


    const [primaryActionButton, setPrimaryActionButton] = useState<ActionButton>(requestTransfer);
    const [alternateActionButton, setAlternateActionButton] = useState<ActionButton>(viewEmployees);
    const [userMessage, setUserMessage] = useState<string>(getOptionToTransferDataMessage(payStubAccessData));
    const [dialogTitle, setDialogTitle] = useState<string>("Pay stub access denied");

    const payStubCreatedBeforeSignIn = userSignedInWithoutAccessButSameAnonUser(payStubAccessData);
    const differentUserPayStub = userSignedInWithoutAccessAndDifferentAnonUser(payStubAccessData);
    const anonIdChangedSincePayStubCreate = userNotSignedInAndNoAccess(payStubAccessData);

    // this has gotten too complicated. If there is a need to add more scenarios, this should all get refactored into scenario objects and a factory that determines which one to instantiate.
    useEffect(() => {
        if (!payStubAccessData.payStubExists) {
            const alternateAction = payStubAccessData.userSignedIn ? viewEmployees : login;
            setPrimaryActionButton(createNewPayStub);
            setAlternateActionButton(alternateAction);
            setUserMessage(getNoPayStubFoundMessage());
            setDialogTitle("Pay stub not found");
            return;
        }
        if (anonIdChangedSincePayStubCreate) {
            setPrimaryActionButton(login);
            setAlternateActionButton(createNewPayStub);
            setUserMessage(getSignInOrSessionTimeoutMessage(payStubAccessData))
            setDialogTitle("Please sign in");
            return;
        }
        if (differentUserPayStub) {
            setPrimaryActionButton(viewEmployees);
            setAlternateActionButton(createNewPayStub);
            setUserMessage(getNoAccessMessage(payStubAccessData))
            return;
        }
        if (payStubCreatedBeforeSignIn) {
            let alternateAction = createNewPayStub;
            let dialogTitle = "New user pay stub access";
            if (payStubAccessData.userSignedInWithoutAccessButHasEmployees) {
                alternateAction = viewEmployees;
                dialogTitle = "Pay stub access";
            }
            setDialogTitle(dialogTitle);
            setPrimaryActionButton(requestTransfer);
            setAlternateActionButton(alternateAction);
            setUserMessage(getOptionToTransferDataMessage(payStubAccessData));
            return;
        }

    }, [requestTransfer, payStubAccessData, viewEmployees, createNewPayStub, login, payStubCreatedBeforeSignIn, differentUserPayStub, anonIdChangedSincePayStubCreate]);

    useEffect(() => {
        if (payStubCreatedBeforeSignIn || anonIdChangedSincePayStubCreate || differentUserPayStub) {
            setOpenDialog(true);
        } else {
            setOpenDialog(false);
        }
    }, [payStubCreatedBeforeSignIn, anonIdChangedSincePayStubCreate, differentUserPayStub])

    return <Dialog
        open={openDialog}
    >
        <DialogTitle>
            {dialogTitle}
            <CloseDialogButton
                close={() => setOpenDialog(false)}
            />
        </DialogTitle>
        <DialogContent>
            <Typography
                variant={"body1"}
            >
                {userMessage}
            </Typography>
            {
                !!error && (
                    <ErrorDisplay
                        stringError={error}
                    />
                )
            }
        </DialogContent>
        <DialogActions>
            <Button
                onClick={alternateActionButton.action}
                variant={"outlined"}
                size={"large"}
                sx={{
                    fontSize: "14px",
                    m: 1,
                    p: 2,
                    '&:hover': {
                        transform: 'translateY(-2px)'
                    }
                }}
            >
                {alternateActionButton.label}
            </Button>
            <Button
                onClick={primaryActionButton.action}
                variant={"outlined"}
                size={"large"}
                disabled={transferLoading || !!transferError}
                sx={{
                    fontSize: "14px",
                    m: 1,
                    p: 2,
                    '&:hover': {
                        transform: 'translateY(-2px)'
                    }
                }}
            >
                {primaryActionButton.label}
            </Button>
        </DialogActions>
    </Dialog>;
}
export default PayStubAccessDialog;