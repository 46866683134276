import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import CloseDialogButton from "../../common/CloseDialogButton";
import React, {useCallback, useContext, useState} from "react";
import {PayStubWithDeductions, useCreateNextPayStubMutation} from "../../graphql/generated/graphql";
import useSystemNotices from "../../Utils/useSystemNotices";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import {assumedUserContext} from "../User/AssumedUserContext";

type PropsType = {
    payStubId: number;
    nextPayStubCreated: (newPayStub: PayStubWithDeductions) => void;
}

const NextPayStub = (props: PropsType) => {
    const {payStubId, nextPayStubCreated} = props;
    const [openDialog, setOpenDialog] = useState(false);
    const {assumedUser} = useContext(assumedUserContext);

    const {sendNotice} = useSystemNotices();
    const [
        createNextPayStub,
        {
            loading: createNextLoading,
            error: createNextError
        }
    ] = useCreateNextPayStubMutation({
        variables: {
            previousPayStubId: payStubId,
            userId: assumedUser.id
        }
    });

    const createNext = useCallback(() => {
        createNextPayStub()
            .then(result => {
                let createNextPayStub = result.data?.createNextPayStub;
                if (!!createNextPayStub && createNextPayStub.successful && !!createNextPayStub.payStubWithDeductions) {
                    nextPayStubCreated(createNextPayStub.payStubWithDeductions);
                } else {
                    sendNotice(`Create next pay stub failed for pay stub id: ${payStubId}`);
                }
            })
            .catch(error => {
                sendNotice(`Create next pay stub failed for pay stub id: ${payStubId} with error: ${error.message}`);
            });
    }, [createNextPayStub, nextPayStubCreated, sendNotice, payStubId]);

    if (!openDialog) {
        return <Button
            sx={{mt: 1, fontSize: '1.5rem'}}
            variant={'contained'}
            size={'large'}
            onClick={() => setOpenDialog(true)}
        >
            Create next pay stub
        </Button>
    }

    return <Dialog
        open={openDialog}
        fullWidth
        sx={{"& .MuiPaper-root": {maxHeight: 700}}}
    >
        <DialogTitle>
            <Typography>
                Create next pay stub
            </Typography>
            <CloseDialogButton
                close={() => setOpenDialog(false)}
            />
        </DialogTitle>
        <DialogContent style={{maxHeight: "calc(100vh - 200px)", overflow: 'auto'}}>
            <Typography
                variant={"body1"}
            >
                This will create the next pay stub for this employee using year-to-date amounts from this pay stub.
                Dates and current period amounts will also be generated but should be reviewed to ensure they accurately
                reflect the pay period.
                Would you like to continue?
            </Typography>
        </DialogContent>

        <DialogActions
            style={{justifyContent: 'flex-start'}}>

            <Button
                sx={{mt: 1, fontSize: '1.5rem'}}
                variant={'contained'}
                size={'large'}
                onClick={() => setOpenDialog(false)}
            >
                Cancel
            </Button>

            <Button
                sx={{mt: 1, fontSize: '1.5rem'}}
                variant={'contained'}
                size={'large'}
                onClick={createNext}
            >
                Continue
            </Button>
        </DialogActions>
        {
            (createNextLoading || !!createNextError) &&
            <LoadingErrorDisplay
                apolloError={createNextError}
                loading={createNextLoading}
            />
        }
    </Dialog>
}
export default NextPayStub;