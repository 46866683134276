import {Grid, Typography} from "@mui/material";
import {ScreenRotation} from "@mui/icons-material";
import React from "react";
import useViewerStyle from "../components/PayStub/useViewerStyle";

const OneTwoPayRotateScreenInstruction = () => {

    const {isScreenWide} = useViewerStyle();
    if (isScreenWide) {
        return <></>;
    }

    return <Grid container direction={"row"}>
        <Typography
            variant={"h5"}
            sx={{mb: 4, mx: 2}}
        >
            Rotate screen for more detail
        </Typography>
        <ScreenRotation
            fontSize={"large"}
        />
    </Grid>

}
export default OneTwoPayRotateScreenInstruction;