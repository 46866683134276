import {useForm} from "react-hook-form";
import {RoeSeparationCode} from "../../../graphql/generated/graphql";
import {Button, Dialog, DialogContent, DialogTitle, Grid, MenuItem, TextField, Typography} from "@mui/material";
import React from "react";
import ReactHookFormSelect from "../../../common/ReactHookFormSelect";
import CloseDialogButton from "../../../common/CloseDialogButton";


export type ReasonForIssuingType = {
    contactAreaCode: string;
    contactFirstName: string;
    contactLastName: string;
    contactPhone: string;
    contactExtension?: string | undefined | null;
    reasonCode: RoeSeparationCode | undefined;
}

type PropsType = {
    reasonForIssuing: ReasonForIssuingType | undefined | null
    updateReasonForIssuing: (reason: ReasonForIssuingType) => void;
    close: () => void;
}

const RoeReasonForIssuing = (props: PropsType) => {
    const {reasonForIssuing, updateReasonForIssuing, close} = props;
    const {
        control,
        register,
        getValues,
        formState: {errors},
        trigger,
    } = useForm<ReasonForIssuingType>({
        defaultValues: {
            ...reasonForIssuing
        }
    });

    function saveReasonForIssuing() {
        trigger()
            .then(formIsValid => {
                if (formIsValid) {
                    const data = getValues();
                    updateReasonForIssuing(data);
                    close();
                }
            });
    }

    const codes = Object.values(RoeSeparationCode);

    return <Dialog
        open={true}
        fullWidth={true}
    >
        <DialogTitle>
            <CloseDialogButton
                close={close}
            />
        </DialogTitle>
        <DialogContent>
            <Typography variant={"h6"}> Reason for issuing ROE</Typography>
            <form noValidate onSubmit={e => {
                saveReasonForIssuing();
                e.preventDefault();
                e.stopPropagation();
            }}>
                <Grid container spacing={2} sx={{mt: 1}}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={"Contact first name"}
                            {...register(`contactFirstName`, {required: "First name is required"})}
                            error={!!errors.contactFirstName}
                            helperText={errors.contactFirstName?.message}
                            defaultValue={reasonForIssuing?.contactFirstName}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={"Contact last name"}
                            {...register(`contactLastName`, {required: "Last name is required"})}
                            error={!!errors.contactLastName}
                            helperText={errors.contactLastName?.message}
                            defaultValue={reasonForIssuing?.contactLastName}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label={"Contact area code"}
                            {...register(`contactAreaCode`, {required: "Area code is required"})}
                            error={!!errors.contactAreaCode}
                            helperText={errors.contactAreaCode?.message}
                            defaultValue={reasonForIssuing?.contactAreaCode}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={"Contact phone"}
                            {...register(`contactPhone`, {required: "Phone is required"})}
                            error={!!errors.contactPhone}
                            helperText={errors.contactPhone?.message}
                            defaultValue={reasonForIssuing?.contactPhone}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label={"Extension"}
                            {...register(`contactExtension`)}
                            error={!!errors.contactExtension}
                            helperText={errors.contactExtension?.message}
                            defaultValue={reasonForIssuing?.contactExtension}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <ReactHookFormSelect
                            control={control}
                            label={"Separation code"}
                            nameOfFormField={`reasonCode`}
                            requiredMessage={"The Separation Code is required"}
                            error={errors.reasonCode}
                        >
                            {codes.map(code => {
                                return <MenuItem key={code} value={code}>
                                    {code}
                                </MenuItem>
                            })}
                        </ReactHookFormSelect>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant={"contained"}
                            style={{fontSize: '1.2rem'}}
                            color={"error"}
                            onClick={() => close()}
                        >
                            {"Cancel"}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant={"contained"}
                            type={"submit"}
                            style={{fontSize: '1.2rem'}}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </DialogContent>
    </Dialog>
}
export default RoeReasonForIssuing;