import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import CloseDialogButton from "./CloseDialogButton";

type PropsType = {
    question: string
    yesLabel?: string;
    noLabel?: string;
    handleBooleanAnswer: (answer: boolean) => void;
    cancel: () => void;
}

const YesOrNoDialog = (props: PropsType) => {
    const {question, yesLabel, noLabel, handleBooleanAnswer, cancel} = props;
    return <Dialog
        open={!!question}
    >
        <DialogTitle>
            <CloseDialogButton
                close={cancel}
            />
        </DialogTitle>
        <DialogContent>
            {question}
        </DialogContent>
        <DialogActions>
            <Button
                variant={'contained'}
                size={'large'}
                type={"button"}
                onClick={() => handleBooleanAnswer(false)}
            >
                {noLabel || "No"}
            </Button>

            <Button
                variant={'contained'}
                size={'large'}
                type={"button"}
                onClick={() => handleBooleanAnswer(true)}
            >
                {yesLabel || "Yes"}
            </Button>
        </DialogActions>
    </Dialog>
}
export default YesOrNoDialog;