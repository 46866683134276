import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3061F6',
        },
        secondary: {
            main: '#2196f3',
        },
    },
    typography: {
        fontFamily: 'Montserrat, Roboto, Arial, sans-serif',
        h1: {
            fontSize: '3.5rem',
            fontWeight: 500,
            '@media (max-width:600px)': {
                fontSize: '3rem',
            },
        },
        h2: {
            fontSize: '2.75rem',
            fontWeight: 500,
            '@media (max-width:600px)': {
                fontSize: '2.5rem',
            },
        },
        h3: {
            fontSize: '2.25rem',
            fontWeight: 500,
            '@media (max-width:600px)': {
                fontSize: '2rem',
            },
        },
        h4: {
            fontSize: '2rem',
            fontWeight: 500,
            '@media (max-width:600px)': {
                fontSize: '1.75rem',
            },
        },
        h5: {
            fontSize: '1.75rem',
            fontWeight: 500,
            '@media (max-width:600px)': {
                fontSize: '1.5rem',
            },
        },
        h6: {
            fontSize: '1.5rem',
            fontWeight: 500,
            '@media (max-width:600px)': {
                fontSize: '1.25rem',
            },
        },
        subtitle1: {
            fontSize: '1.25rem',
            fontWeight: 400,
            '@media (max-width:600px)': {
                fontSize: '1.1rem',
            },
        },
        subtitle2: {
            fontSize: '1.15rem',
            fontWeight: 400,
            '@media (max-width:600px)': {
                fontSize: '1rem',
            },
        },
        body1: {
            fontSize: '1.4rem',
            fontWeight: 400,
            '@media (max-width:600px)': {
                fontSize: '1.3rem',
            },
        },
        body2: {
            fontSize: '1.2rem',
            fontWeight: 400,
            '@media (max-width:600px)': {
                fontSize: '1.1rem',
            },
        },
        button: {
            fontSize: '1rem',
            fontWeight: 500,
            '@media (max-width:600px)': {
                fontSize: '0.875rem',
            },
        },
        caption: {
            fontSize: '0.875rem',
            fontWeight: 400,
            '@media (max-width:600px)': {
                fontSize: '0.8rem',
            },
        },
        overline: {
            fontSize: '0.875rem',
            fontWeight: 400,
            '@media (max-width:600px)': {
                fontSize: '0.8rem',
            }
        },
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: '#bdbdbd',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '1.25rem',
                },
            },
        },
    },
});

export default theme;
