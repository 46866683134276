import {Button, ClickAwayListener, Grid, List} from "@mui/material";
import React from "react";

import styles from './MobileMenu.module.scss';
import {APP_LINK, APP_LINK_LOG_IN, MARKETING_LINK} from "../../common/AppLinks";
import logo from '../../images/logoTransparent.png';
import {Link} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import usePrimaryButton, {ActionButton} from "../Header/usePrimaryButton";

type PropsType = {
    setShowMobile: (show: boolean) => void;
    showMobile: boolean;
    isLegacyUser: boolean;
}
const MobileMenu = (props: PropsType) => {

        const {isAuthenticated, logout} = useAuth0();
    const {getPrimaryButtonDefinition} = usePrimaryButton();

        const handleLogout = () => {
            logout({
                logoutParams: {
                    returnTo: process.env.REACT_APP_MARKETING_URL,
                },
            });
        };

    const signInSignOutButton = isAuthenticated
        ? <Grid item xs={10} sm={6}>
            <Button
                onClick={handleLogout}
                variant={"outlined"}
                size={"large"}
                // width={"80%"}
                sx={{
                    // todo: Kenny. Would be good if we didn't need "!important" here. I'm not sure where the overriding style is coming from but it is trumping the 14px size unless I add this.
                    fontSize: "14px !important",
                    width: "90%",
                    // height: "90%"
                    mx: 1,
                    py: 1.6,
                    '&:hover': {
                        transform: 'translateY(-2px)'
                    }
                }}
            >
                Sign out
            </Button>

        </Grid>

            :
        <Grid item xs={10} sm={6}>
                <Button href={APP_LINK_LOG_IN} className="cBtn cBtnOutline cBtnUppercase ml10">
                    My Account
                </Button>
            </Grid>;


    function getMenuItems() {
        if (!isAuthenticated) {
            return <List>
                <li>
                    <a href={MARKETING_LINK + "/our-pay-stubs"}>Our pay stubs</a>
                </li>
                <li>
                    <a href={MARKETING_LINK + "/blog"}>Blog</a>
                </li>
                <li>
                    <a href={MARKETING_LINK + "/guide"}>Guide</a>
                </li>
                <li>
                    <a href={MARKETING_LINK + "/faq"}>Faq</a>
                </li>
                <li>
                    <a href={MARKETING_LINK + "/pricing"}>Pricing</a>
                </li>
                <li>
                    <a href={MARKETING_LINK + "/contact-us"}>Contact</a>
                </li>
            </List>;
        }
        return <List>
            {
                props.isLegacyUser && (
                    <li>
                        <a href={APP_LINK}>My pay stubs</a>
                    </li>
                )
            }
            <li>
                <a href={"/employees"}>My employees</a>
            </li>
            <li>
                <a href={APP_LINK + "/account"}>My account</a>
            </li>
            <li>
                <a href={"/reports"}>Reports</a>
            </li>
            <li>
                <a href={MARKETING_LINK + "/guide"}>Guide</a>
            </li>
            <li>
                <a href={MARKETING_LINK + "/faq"}>Faq</a>
            </li>
            <li>
                <a href={MARKETING_LINK + "/pricing"}>Pricing</a>
            </li>
        </List>;
    }

    function getPrimaryMenuItem(buttonDef: ActionButton) {
        return <Button
            onClick={buttonDef.action}
            className="cBtn"
        >
            {buttonDef.label}
        </Button>;
    }

    return <ClickAwayListener
        onClickAway={() => {
            props.setShowMobile(false);
        }}
    >
        <Grid className={`${styles.mobileMenu} ${props.showMobile ? '' : 'hidden'}`}>
            <Grid className={`${styles.sLogo}`}>
                <Link to="/">
                    <>
                        <img width={125} height={125} src={logo} alt="OneTwoPay Home"/>
                    </>
                </Link>
            </Grid>
            {getMenuItems()}
            {signInSignOutButton}
            <Grid sx={{pl: 1, mt: 1}}>
                {getPrimaryMenuItem(getPrimaryButtonDefinition())}
            </Grid>
        </Grid>
    </ClickAwayListener>
    }
;

export default MobileMenu;
